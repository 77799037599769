<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left mt-12 pb-32">
    <h1 class="text-3xl text-center">
      What the POTUS?
    </h1>
    
    <SpeechBubble
      :img="state.images.eagleRight"
      :show="true"
      :potus="''"
    >
      <p>
        PixelPotus is a digital collectable competition built on the Tezos blockchain. 
      </p>
      <br>
      <p>
        There are 45 presidents* with 6 different rarities for each, with custom artwork for each president at each level. Keep reading, or see our <a
          class="text-blue-light"
          href="https://medium.com/@pixelpotus/pixelpotus-is-live-on-the-mainnet-2ad043eaa074"
          target="_blank"
        >medium article</a> for a deep dive.

        <br><br><span class="text-xs">(*#22, Grover Cleveland, had a second nonconsecutive term as #24)</span>
      </p>
    </SpeechBubble>

    <img
      class="w-full"
      src="@/assets/heart.jpg"
    >

    <SpeechBubble
      :img="state.images.jefferson"
      :show="true"
      :potus="'Jefferson'"
    >
      <p>
        We are minting and giving away 10,000 copies of the common level tokens for FREE to anyone who comes to claim from the website.
      </p>
      <br>
      <p>
        Each higher rarity level can only be created by collecting and then burning multiples of lower rarities. For instance, you need 2 common George Washingtons in order to upgrade and create 1 Uncommon. 
      </p>
    </SpeechBubble>

    <img
      class="w-full"
      src="@/assets/level-up.png"
    >
    
    <SpeechBubble
      :img="state.images.pierce"
      :show="true"
      :potus="'Pierce'"
      :is-left="false"
    >
      <p>
        Each person can claim {{ maxFreeClaims }} free airdropped POTUS at random from the faucet each day. We only allow {{ maxClaimsPerPeriod }} claims per period total, so it's important to get your claim in as early as possible so you don't miss out. The free POTUS will be of common rarity.
      </p>
      <br>
      <p>
        We also allow each person to purchase up to {{ maxPaidClaims }} additional random POTUSes of common rarity for {{ state.convertFromMutez(claimingPrice) }} tez each, limited by the {{ maxClaimsPerPeriod }} total claims per period.
      </p>
    </SpeechBubble>
    
    <SpeechBubble
      :img="state.images.obama"
      :show="true"
      :potus="'Obama'"
    >
      <p>
        You can trade your collection freely in the marketplace. Collecting Polks and have some spare Clevelands? Just post a trade in the marketplace and someone else from the community may take you up on it! You can also offer trades for $$$ as well.
      </p>
      <br>
      <p>
        The highest level rarity is a Unique NFT - just like Highlander, there can be only one. Whoever is the first to collect and upgrade enough Washingtons to create the highest level will be the only person in the world to own the unique token with its associated unique artwork.
      </p>
    </SpeechBubble>

    <h2 class="text-3xl text-center">
      How to get started
    </h2>

    <div class="video-container">
      <iframe
        class="m-auto"
        src="https://www.youtube-nocookie.com/embed/PMrYsM5zDBo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>

    <SpeechBubble
      :img="state.images.nixon"
      :show="true"
      :potus="'Nixon'"
    >
      <div class="sm:hidden">
        You need a Tezos wallet - we like the Kukai web wallet - it works on mobile and makes interacting with PixelPotus and other apps easier-> <a
          class="text-blue-light"
          href="https://kukai.app/"
          target="_blank"
        >Kukai</a>
      </div>
      <div class="hidden sm:inline">
        You need a Tezos wallet - we like the Temple web wallet - its a chrome extension and makes interacting with PixelPotus and other apps easier-> <a
          class="text-blue-light"
          href="https://thanoswallet.com/"
          target="_blank"
        >Temple Wallet</a>
      </div>
    </SpeechBubble>

    <SpeechBubble
      :img="state.images.clinton"
      :show="true"
      :potus="'Clinton'"
      :is-left="false"
    >
      Once that's installed, follow the steps in the extension to create a shiny new wallet. Please backup your wallet seed and password. No one can help you if you lose them and you can lose access to your assets forever.
    </SpeechBubble>

    <SpeechBubble
      :img="state.images.kennedy"
      :show="true"
      :potus="'Kennedy'"
    >
      You will need a little bit of XTZ (Tezos) in your wallet to pay for gas fees on the network. Head over to your favorite exchange (<a
        class="text-blue-light"
        href="https://www.coinbase.com/price/tezos"
        target="_blank"
      >Coinbase</a>, <a
        class="text-blue-light"
        href="https://www.gemini.com/prices/tezos"
        target="_blank"
      >Gemini</a>, <a
        class="text-blue-light"
        href="https://www.binance.com/en/trade/XTZ_USDT"
        target="_blank"
      >Binance</a>) and grab a few XTZ, then send it to your new wallet address. Once you see the new XTZ balance show up in your wallet, you are ready to start collecting some PixelPOTUS!
    </SpeechBubble>

    <SpeechBubble
      :img="state.images.greatseal"
      :show="true"
      :potus="'Illuminati'"
      :is-left="false"
      class=""
    >
      Go to <a
        class="text-blue-light"
        href="https://www.pixelpotus.com"
      >Pixelpotus.com</a> and click the giant Connect Wallet button. From there you can start claiming free POTUSes, trading in the marketplace and upgrading to build your collection.
    </SpeechBubble>
    <div 
      v-if="state.userAddress" 
      class="grid grid-cols-1 md:grid-cols-3 gap-12 items-center justify-between mt-12 mb-24"
    >
      <div
        v-for="(b, i) in btns"
        :key="i"
      >
        <button
          :class="b.styles"
          class="uppercase py-4 px-6 border-2 border-gray-700 mb-2"
          @click="b.click"
        >
          {{ b.label }}
        </button>
        <p class="text-sm pt-5">
          {{ b.description }}
        </p>
      </div>
    </div>
    <div
      class="nes-container is-rounded bg-brown-dark overflow-ellipsis overflow-hidden"
    >
      Official PixelPotus Links 
      <br><br>
      Contract Address:
      <a
        v-if="state.isMobile"
        :href="`https://better-call.dev/mainnet/${state.contract}/operations`"
        target="_blank"
        class="sm:hidden overflow-ellipsis overflow-hidden"
      >{{ formattedAddr }}</a>
      <a
        v-else
        :href="`https://better-call.dev/mainnet/${state.contract}/operations`"
        target="_blank"
        class="overflow-ellipsis overflow-hidden"
      >{{ state.contract }}</a>
      <br><br>
      Twitter:<a
        href="https://twitter.com/PixelPotus"
        target="_blank"
        class="overflow-ellipsis overflow-hidden"
      >@PixelPotus</a>
      <br><br>
      Discord:<a
        href="https://discord.gg/CbdbvwtwkM"
        target="_blank"
        class="overflow-ellipsis overflow-hidden"
      >Invitation</a>
      <br><br>
      Telegram:<a
        href="https://t.me/pixelpotus"
        target="_blank"
        class="overflow-ellipsis overflow-hidden"
      >Invitation</a>
    </div>
    <div class="text-center w-full">
      <div class="p-5">
        <a
          href="https://www.producthunt.com/posts/pixelpotus?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-pixelpotus"
          target="_blank"
          class="m-auto"
        ><img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=299093&theme=dark"
          alt="PixelPotus - A president themed collectable card NFT game built on Tezos | Product Hunt"
          style="width: 250px; height: 54px;"
          width="250"
          height="54"
          class="m-auto"
        ></a>
        <span class="text-xs">(Check it out and spread the word!)</span>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Claim',
  components: {
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      periodLen: 21600,
      maxFreeClaims: 1,
      maxPaidClaims: 5,
      claimingPrice: 125000,
      maxClaimsPerPeriod: 0,
    }
  },
  async mounted() {
    await this.getClaimStorage()
  },
  methods: {
    async getClaimStorage () {
      const claimResp = await axios({
          url:'/api/claimMeta', 
        })
      this.periodLen = claimResp.data.meta.periodLen
      this.claimingPrice = claimResp.data.meta.claimingPrice
      this.maxClaimsPerPeriod = claimResp.data.meta.maxClaimsPerPeriod
      this.maxFreeClaims = claimResp.data.meta.maxFreeClaims
      this.maxPaidClaims = claimResp.data.meta.maxPaidClaims
    },
  },
}
</script>