<template>
  <div class="auctions">
    <div
      v-if="!trading"
      class="p-5 nes-container is-rounded"
    >
      <label
        for="filter_trades"
        class="block text-lg font-medium text-gray-700"
      >Filters
        <button @click="showFilters = !showFilters">
          <fa 
            v-if="!showFilters"
            icon="chevron-right" 
            class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
          />
          <fa 
            v-if="showFilters"
            icon="chevron-down" 
            class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
          />
        </button>
        <button
          class="float-right text-blue-link hover:text-blue-hov text-xs"
          @click="clearFilters"
        >
          reset
        </button>
      </label>
      <span v-show="showFilters">
        <input
          id="filter_trades"
          v-model="filter"
          type="text"
          name="filter_trades"
          class="w-full p-2 border-2 mt-1 focus:ring-blue-500 focus:border-blue-500 block shadow-sm text-xs sm:text-sm border-gray-300 rounded-none w-full md:w-3/5 xl:w-1/3 "
          placeholder="potus name or token id"
          @input="updateFilters()"
        >
        <hr>
        <div class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <input
            id="toggleOnlyShowMyOpenOffers"
            v-model="onlyShowMyOpenOffers"
            type="checkbox"
            name="toggleOnlyShowMyOpenOffers"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @input="updateCheckbox()"
          >
          <label
            for="toggleOnlyShowMyOpenOffers"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          />
        </div>
        <label
          for="toggleOnlyShowMyOpenOffers"
          class="text-xs text-gray-700 inline"
        >Only show my offers</label>
        <hr>
        <div class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <input
            id="toggleShowEndedAuctions"
            v-model="showEndedAuctions"
            type="checkbox"
            name="toggleShowEndedAuctions"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @input="updateCheckbox()"
          >
          <label
            for="toggleShowEndedAuctions"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          />
        </div>
        <label
          for="toggleShowEndedAuctions"
          class="text-xs text-gray-700 inline"
        >Show ended auctions</label>

        <hr>
        <label
          class="text-sm font-medium text-gray-700"
        >Auction Type:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="tradeTypes"
            :options="tradeTypesOptions"
            :show-labels="false"
            @select="tradeTypesSelected"
          />
        </div>

        <hr>
        <label
          class="text-sm font-medium text-gray-700"
        >Sorted by:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="sortedBy"
            :options="sortedByOptions"
            :show-labels="false"
            @select="sortedBySelected"
          />
        </div>
        <hr>
        <label
          class="text-sm font-medium text-gray-700"
        >Rarities:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="rarities"
            :options="raritiesOptions"
            :multiple="true"
            placeholder="Select Rarities"
            :show-labels="false"
            @select="tradeTypesSelected"
            @remove="tradeTypesSelected"
          />
        </div>
      </span>
    </div>
    
    <div
      class="flex flex-col gap-2"
    >
      <Pagination 
        :offset="offset"
        :limit="limit"
        :pages="pages"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="3">
            3
          </option>
          <option value="6">
            6
          </option>
          <option value="9">
            9
          </option>
          <option value="30">
            30
          </option>
          <option value="60">
            60
          </option>
        </template>
      </Pagination>
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        <Auction
          v-for="auction in auctions"
          :key="auction.auctionId"
          :auction="auction"
          :bid-complete="bidComplete"
          :claim-complete="claimComplete"
          :show-link="true"
        />
        <div
          v-if="auctions.length == 0"
          class="m-auto col-span-3"
        >
          No Auctions Found
        </div>
      </div>
      <Pagination 
        :offset="offset"
        :limit="limit"
        :pages="pages"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="3">
            3
          </option>
          <option value="6">
            6
          </option>
          <option value="9">
            9
          </option>
          <option value="30">
            30
          </option>
          <option value="60">
            60
          </option>
        </template>
      </Pagination>
    </div>
  </div>
</template>

<script>
import * as signalR from '@microsoft/signalr'
import axios from 'axios'
import { debounce,find,findIndex } from 'lodash'
import moment from 'moment'
import VueMultiselect from 'vue-multiselect'

import Auction from '../components/Auction'
import Pagination from '../components/Pagination'

export default {
  name: 'Auctions',
  components: {
    VueMultiselect,
    Auction,
    Pagination,
  },
  data () {
    return {
      state: this.$root.$data.state,
      auctions: [],
      totalAuctions: 0,
      loading: false,
      trading: false,
      showFilters: true,
      filter: '',
      onlyShowMyOpenOffers: false,
      showEndedAuctions: false,
      sortDir: 'asc',
      orderBy: 'end_timestamp',
      tradeTypes: 'All',
      tradeTypesOptions: ['All', 'English (Increasing)', 'Dutch (Decreasing)'],
      sortedBy: 'Ending Soon',
      sortedByOptions: ['Ending Soon', 'Recently Listed', 'Number of Bids', 'Price: Low to High', 'Price: High to Low'],
      rarities: [],
      raritiesOptions: ['common','uncommon','rare','epic','legendary','unique'],
      limit: 9,
      offset: 0, 
      getLoop: false,
      socket: null,
      shouldRecon: true,
      autosave: {
        filter: '',
        onlyShowMyOpenOffers: false,
        showEndedAuctions: false,
        tradeTypes: 'All',
        sortedBy: '',
        rarities: [],
      },
    }
  },
  computed: {
    pages () {
      if (this.auctions.length === 0) {
        return 1
      }
      // let tot = parseInt(().toFixed(0))
      let tot = Math.floor(this.totalAuctions / this.limit)
      if (tot < (this.totalAuctions / (this.limit))) { tot++ }
      return tot
    },
  },
  watch: {
    filter() {
      this.saveFilters()
    },
    onlyShowMyOpenOffers() {
      this.saveFilters()
    },
    showEndedAuctions() {
      this.saveFilters()
    },
    tradeTypes() {
      this.saveFilters()
    },
    sortedBy() {
      this.saveFilters()
    },
    rarities() {
      this.saveFilters()
    },
  },
  async mounted() {
    this.state.log('mounted Auctions')

    const filters = localStorage.getItem('auctionFilters')
    const autosave = JSON.parse(filters)
    if (autosave) {
      this.filter = autosave.filter
      this.onlyShowMyOpenOffers = autosave.onlyShowMyOpenOffers
      this.showEndedAuctions = autosave.showEndedAuctions
      this.tradeTypes = autosave.tradeTypes
      this.sortedBy = autosave.sortedBy
      this.rarities = autosave.rarities
    }

    this.shouldRecon = true
    await this.getAuctions()
    await this.initSocket()
  },
  async unmounted() {
    this.state.log('unmounted Auctions')
    this.shouldRecon = false
    if (this.socket) {
      this.socket.stop()
      this.socket.off()
      this.socket = null
    }
  },
  methods: {
    clearFilters() {
      this.filter = ''
      this.onlyShowMyOpenOffers = false
      this.showEndedAuctions = false
      this.tradeTypes = 'All'
      this.sortedBy = 'Ending Soon'
      this.rarities.length = 0
      this.saveFilters()
      this.getAuctions()
    },
    saveFilters() {
      this.autosave.filter = this.filter
      this.autosave.onlyShowMyOpenOffers = this.onlyShowMyOpenOffers
      this.autosave.showEndedAuctions = this.showEndedAuctions
      this.autosave.tradeTypes = this.tradeTypes
      this.autosave.sortedBy = this.sortedBy
      this.autosave.rarities = this.rarities
      localStorage.setItem('auctionFilters', JSON.stringify(this.autosave))
    },
    async initSocket() {
      if (!this.socket) {
        this.socket = new signalR.HubConnectionBuilder()
          .withUrl(`https://api${process.env.VUE_APP_TZKT}.tzkt.io/v1/events`)
          .build()
        // open connection
        await this.socket.start()
        // subscribe to auction transactions
        await this.socket.invoke('SubscribeToOperations', {
          address: process.env.PP_AUCTION,
          types: 'transaction',
        })

        // auto-reconnect
        this.socket.onclose(()=>{
          this.socket = null
          if(this.shouldRecon) {
            this.initSocket()
          }
        })

        this.socket.on('operations', async (msg) => {
          let load = false
          if (msg.type == 1) {
            await Promise.all(
              msg.data.map(async (tx) => {
                if (tx.status == 'applied' && tx.parameter) {
                  if (['create_auction','conclude_auction','bid'].includes(tx.parameter.entrypoint)) {
                    load = true
                  }
                }
              }),
            )
            if (load) {
              await new Promise((resolve) => setTimeout(resolve, 1000))
              this.getAuctions()
            }
          }
        })
      }
    },
    async refresh() {
      this.getAuctions(true)
    },
    async tradeTypesSelected () {
      await new Promise((resolve) => setTimeout(resolve, 250))
      this.offset = 0
      this.getAuctions(true)
    },
    async pageSizeSelected (value) {
      this.limit = parseInt(value)
      this.offset = 0
      this.getAuctions(true)
    },
    async sortedBySelected () {
      await new Promise((resolve) => setTimeout(resolve, 250))
      switch(this.sortedBy) {
        case 'Recently Listed':
          this.sortDir = 'desc'
          this.orderBy = 'start_timestamp'
          break
        case 'Price: Low to High':
          this.sortDir = 'asc'
          this.orderBy = 'cur_price'
          break
        case 'Price: High to Low':
          this.sortDir = 'desc'
          this.orderBy = 'cur_price'
          break
        case 'Number of Bids':
          this.sortDir = 'desc'
          this.orderBy = 'bids'
          break
        default:
          this.sortedBy = 'Ending Soon'
          this.sortDir = 'asc'
          this.orderBy = 'end_timestamp'
          break
      }
      this.offset = 0
      this.getAuctions(true)
    },
    async updateCheckbox() {
      await new Promise((resolve) => setTimeout(resolve, 250))
      this.offset = 0
      this.getAuctions(true)
    },
    updateFilters() {
      this.debouncedUpdate()
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.getAuctions(true)
    }, 600),
    getMd(tokenId) {
      return find(this.state.allTokens, (t) => {return t.tokenId === tokenId})
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.totalAuctions) {this.offset -= (this.limit)}
      else { this.getAuctions(true) }
    },
    prevPage() {
      this.offset -= this.limit
      if (this.offset < 0) {this.offset = 0}
      else { this.getAuctions(true) }
    },
    firstPage() {
      this.offset = 0
      this.getAuctions(true)
    },
    lastPage() {
      this.offset = (this.pages - 1) * (this.limit)
      this.getAuctions(true)
    },
    async getAuctions () {
      if(this.loading) {
        return
      }
      this.state.log('getAuctions')
      this.loading = true

      const params = {
        limit: this.limit,
        offset: this.offset,
        filter: this.filter,
        sortDir: this.sortDir,
        orderBy: this.orderBy,
        rarities: this.rarities.join(','),
        showEnded: this.showEndedAuctions,
        type: this.tradeTypes.toLowerCase(),
      }
      if (this.onlyShowMyOpenOffers) {
        params.seller = this.state.userAddress
      }
      const resp = await axios({
        url:'/api/auctions', 
        params,
      })
      this.auctions = resp.data.auctions.map((auction) => {
        const alias = find(this.state.aliases, (a) => {return a.address === auction.seller})
        if(alias) {
          auction.sellerAlias = alias.alias
        }
        const bidderAlias = find(this.state.aliases, (a) => {return a.address === auction.bidder})
        if(bidderAlias) {
          auction.bidderAlias = bidderAlias.alias
        }
        return auction
      })
      this.totalAuctions = resp.data.total

      this.loading = false
    },
    async bidComplete(auctionId, bidAmount) {
      const idx = findIndex(this.auctions, auc => auc.auctionId === auctionId)
      if (idx > -1) {
        this.auctions[idx].bidder = this.state.userAddress
        this.auctions[idx].bidderAlias = this.state.userAlias
        this.auctions[idx].bidAmount = bidAmount
        this.auctions[idx].bids++
        if (bidAmount == this.auctions[idx].buyNow) {
          this.auctions[idx].endTimestamp = new Date().toISOString()
        }
        const countDownDate = moment(this.auctions[idx].endTimestamp).valueOf()
        var now = new Date().getTime()
        var distance = countDownDate - now
        if (distance < (1000*60*5) && !this.auctions[idx].dutch && bidAmount != this.auctions[idx].buyNow) {
          this.auctions[idx].endTimestamp = moment(this.auctions[idx].endTimestamp).add(5, 'minutes').toISOString()
        }
        if (this.auctions[idx].dutch || bidAmount == this.auctions[idx].buyNow || moment(this.auctions[idx].endTimestamp).isBefore(moment())) {
          this.showEndedAuctions = true
        }
      }
    },
    async claimComplete(auctionId) {
      const idx = findIndex(this.auctions, auc => auc.auctionId === auctionId)
      if (idx > -1) {
        this.auctions.splice(idx, 1)
      }
    },
  },
}
</script>