<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left mt-12 overflow-hidden">
    <h1 class="text-2xl text-center">
      Global POTUS Stats<br>
      <span
        v-if="!loading"
        class="inline text-center text-xs"
      >
        Last refresh: {{ statsTimestamp ? statsTimestamp.fromNow() : 'never' }} <button
          v-if="statsTimestamp"
          class="text-blue-link hover:text-blue-hov"
          @click="refresh"
        ><fa 
          icon="sync"
          class="h-5 w-5 align-bottom"
        /></button>
      </span>
    </h1>
    <div
      v-if="loading"
      class="m-auto"
    >
      Loading...
    </div>
    <div
      v-if="state.seriesMeta"
      class="nes-container is-rounded text-gray-600"
    >
      <h1 class="text-xl text-center text-gray-700">
        Upgrade Requirements
      </h1>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 justify-between">
        <div
          v-for="(sm, key) in state.seriesMeta"
          :key="key"
          class="text-gray-700"
        >
          To {{ sm.to }}
          <div class="text-sm text-gray-600">
            <img
              width="10"
              class="inline mr-2 mb-2 "
              src="../assets/flame.png"
            >{{ sm.upgradeReq }} {{ sm.from }}
          </div>
          <div class="text-sm squared-full text-green-800">
            <fa 
              icon="coins" 
              class="h-5 w-5 mr-1"
            />{{ state.convertFromMutez(sm.upgradeCost) }}<span class="text-gray-500">tez</span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="stats"
      class="p-5 nes-container is-rounded"
    >
      <label
        for="filter_trades"
        class="block text-lg font-medium text-gray-700"
      >Filters
        <button @click="showFilters = !showFilters">
          <fa 
            v-if="!showFilters"
            icon="chevron-right" 
            class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
          />
          <fa 
            v-if="showFilters"
            icon="chevron-down" 
            class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
          />
        </button>
        <button
          class="float-right text-blue-link hover:text-blue-hov text-xs"
          @click="clearFilters"
        >
          reset
        </button>
      </label>
      <span v-show="showFilters">
        <input
          id="filter_trades"
          v-model="filter"
          type="text"
          name="filter_trades"
          class="w-full p-2 border-2 mt-1 focus:ring-blue-500 focus:border-blue-500 block shadow-sm text-xs sm:text-sm border-gray-300 rounded-none w-full md:w-3/5 xl:w-1/3 "
          placeholder="potus name"
          @input="updateFilters()"
        >
        <label
          class="text-sm font-medium text-gray-700"
        >Rarities:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="rarities"
            :options="raritiesOptions"
            :multiple="true"
            placeholder="Select Rarities"
            :show-labels="false"
            @select="updateFilters"
            @remove="updateFilters"
          />
        </div>
      </span>
    </div>

    <div
      v-if="!loading"
      class="flex flex-col"
    >
      <span class="sm:hidden text-xs text-center mb-5">Drag to scroll horizontally</span>
      <div class="-my-2 sm:-mx-6 lg:-mx-8 mb-12">
        <div class="table-container py-2 align-middle overflow-auto inline-block sm:px-6 lg:px-8">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class="divide-y divide-gray-200 table-auto">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    POTUS
                  </th>
                  <th
                    v-show="rarities.length == 0 || rarities.includes('common')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Common
                  </th>
                  <th
                    v-show="rarities.length == 0 || rarities.includes('uncommon')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Uncommon
                  </th>
                  <th
                    v-show="rarities.length == 0 || rarities.includes('rare')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rare
                  </th>
                  <th
                    v-show="rarities.length == 0 || rarities.includes('epic')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Epic
                  </th>
                  <th
                    v-show="rarities.length == 0 || rarities.includes('legendary')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Legendary
                  </th>
                  <th
                    v-show="rarities.length == 0 || rarities.includes('unique')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Unique
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(series, skey) in filteredStats"
                  :key="skey"
                >
                  <th class="px-6 py-4 whitespace-nowrap">
                    <div class="flex flex-col items-left">
                      <div
                        class="text-sm font-medium text-gray-900 p-1"
                      >
                        {{ series.seriesId }} {{ series.levels[0].metadata.name.split(' (')[0] }}
                      </div>
                    </div>
                  </th>
                  <td
                    v-for="(lvl, lvlkey) in series.levels"
                    v-show="rarities.length == 0 || rarities.includes(lvl.rarity)"
                    :key="lvlkey"
                    class="px-3 py-2 whitespace-nowrap border-l-2"
                  >
                    <router-link :to="'/potus/' + lvl.tokenId">
                      <div class="flex items-center ">
                        <div class="flex-shrink-0 w-10">
                          <img
                            class="w-10 square-full"
                            :src="lvl.metadata.displayUri"
                            alt=""
                          >
                        </div>
                        <div class="ml-2 pl-2">
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              cap:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.cap }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              minted:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.minted }}
                            </div>
                          </div>
                          <div
                            v-if="lvl.tokenId < 6000"
                            class="grid grid-cols-2 text-xs text-gray-600 w-48"
                          >
                            <div class="text-right">
                              burned:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.burned }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              float:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.minted - lvl.burned }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              trades:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.trades ? lvl.trades : '-' }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              sales:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.sales ? lvl.sales : '-' }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              high:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.high ? state.convertFromMutez(lvl.high, 2) + 'tez' : '-' }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              low:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.low ? state.convertFromMutez(lvl.low, 2) + 'tez' : '-' }}
                            </div>
                          </div>
                          <div class="grid grid-cols-2 text-xs text-gray-600 w-48">
                            <div class="text-right">
                              last:
                            </div>
                            <div class="text-left bg-green-100 text-green-800">
                              {{ lvl.last ? state.convertFromMutez(lvl.last, 2) + 'tez' : '-' }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'Stats',
  components: {
    VueMultiselect,
  },
  data () {
    return {
      state: this.$root.$data.state,
      loading: true,
      showFilters: true,
      filter: '',
      rarities: [],
      raritiesOptions: ['common','uncommon','rare','epic','legendary','unique'],
      filteredStats: [],
      autosave: {
        filter: '',
        rarities: [],
      },
    }
  },
  computed: {
    stats () {
      return this.state.stats
    },
    statsTimestamp () {
      return this.state.statsTimestamp
    },
  },
  watch: {
    filter() {
      this.saveFilters()
    },
    rarities() {
      this.saveFilters()
    },
  },
  async mounted() {
    this.state.log('Mounted stats')

    const filters = localStorage.getItem('statsFilter')
    const autosave = JSON.parse(filters)
    if (autosave) {
      this.filter = autosave.filter
      this.rarities = autosave.rarities
    }

    this.load()
  },
  methods: {
    clearFilters() {
      this.filter = ''
      this.rarities.length = 0
      this.saveFilters()
      this.load()
    },
    saveFilters() {
      this.autosave.filter = this.filter
      this.autosave.rarities = this.rarities
      localStorage.setItem('statsFilter', JSON.stringify(this.autosave))
    },
    async load() {
      if (this.stats === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        await this.load()
      } else {
        this.state.log('Stats loaded')
        this.filteredStats = this.stats.series
        this.updateFilters()
        this.loading = false
      }
    },
    async refresh () {
      this.loading = true
      await this.state.loadAllTokens(true)
      await this.state.loadStats(true)
      this.loading = false
    },
    updateFilters() {
      this.debouncedUpdate()
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.filteredStats = this.stats.series.filter(t => {
        let sRet = true
        
        if (this.filter !== '' && t.levels[0].metadata.name.toLowerCase().indexOf(this.filter.toLowerCase()) == -1) {
          sRet = false
        }

        return sRet
      })
    }, 50),
  },
}
</script>
<style scoped>
.table-container {
  width: 1280px;
  height: 700px;
}

table thead th {
  position: sticky;
  top: -8px;
  z-index: 1;
  width: 25vw;
  background: white;
}

table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
</style>