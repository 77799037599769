<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-0 items-center justify-between text-left mt-12 overflow-hidden">
    <h1 class="text-2xl text-center">
      <AddrIcon
        :address="owner"
        :alias="alias"
      />'s Collection
    </h1>

    <div
      v-if="loading || !tokensLoaded"
      class="text-center"
    >
      Loading...
    </div>
    <div
      v-if="tokensLoaded && tokens.length"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        <div
          v-for="(tok, tkey) in tokens.slice(offset, offset+limit)"
          :key="tkey"
          class="text-left cursor-pointer"
          @click="$router.push('/potus/'+tok.tokenId)"
        >
          <div class="flex-shrink-0 w-50 pt-3">
            <img
              class="w-full squared-full"
              :class="{'vibrate-1': tok.upgrading}"
              :src="tok.metadata.displayUri"
              alt=""
            >
            <div class="-mt-6 text-left ml-1">
              <span class="bg-white rounded p-1 opacity-50">x{{ tok.balance }}</span>
            </div>
            <div class="-mt-6 text-right mr-1">
              <span class="bg-white rounded p-1 opacity-50">{{ tok.tokenId }}</span>
            </div>
          </div>
        </div>
      </div>
      <Pagination 
        :offset="offset"
        :limit="limit"
        :pages="pages"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="4">
            4
          </option>
          <option value="12">
            12
          </option>
          <option value="20">
            20
          </option>
          <option value="40">
            40
          </option>
          <option value="60">
            60
          </option>
        </template>
      </Pagination>
    </div>

    <div
      v-if="trades.length"
    >
      <h1 class="text-2xl text-center">
        Open Trades
      </h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        <div
          v-for="(trade, tkey) in trades"
          :key="tkey"
          class="flex flex-col nes-container is-rounded text-center"
        >
          <AddrIcon
            :address="trade.owner"
            :alias="trade.alias"
          /> will give
          <div class="flex flex-col items-left text-left">
            <div
              v-if="trade.price_offered > 0"
              class="text-sm font-medium text-center text-gray-900 p-1"
            >
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                {{ state.convertFromMutez(trade.price_offered) }} tez
              </span>
            </div>
            <div
              v-for="(tok, okey) in trade.offered"
              :key="okey"
              class="text-sm font-medium text-gray-900 p-1"
            >
              <span
                v-if="okey > 0"
                class="mb-5 text-gray-500"
              >
                and
              </span>
              <div class="flex items-center">
                <div class="flex-shrink-0 w-10">
                  <img
                    class="w-10 squared-full"
                    :src="tok.metadata.displayUri"
                    alt=""
                  >
                </div>
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    {{ tok.metadata.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ tok.metadata.rarity }} x{{ tok.amount }}
                  </div>
                  <div class="text-sm text-gray-500">
                    Token ID: {{ tok.tokenId }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          for
          <div class="flex flex-col items-left text-left">
            <div
              v-for="(tok, okey) in trade.accepted"
              :key="okey"
              class="text-sm font-medium text-gray-900 p-1"
            >
              <span
                v-if="okey > 0"
                class="mb-5 text-gray-500"
              >
                and
              </span>
              <div class="flex items-center">
                <div class="flex-shrink-0 w-10">
                  <img
                    class="w-10 squared-full"
                    :src="tok.metadata.displayUri"
                    alt=""
                  >
                </div>
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    {{ tok.metadata.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ tok.metadata.rarity }} x{{ tok.amount }}
                  </div>
                  <div class="text-sm text-gray-500">
                    Token ID: {{ tok.tokenId }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="trade.price_accepted > 0"
              class="text-sm font-medium text-gray-900 p-1"
            >
              <span v-if="trade.price_accepted && trade.accepted.length > 0">or</span>
              <span
                v-if="trade.price_accepted"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                {{ state.convertFromMutez(trade.price_accepted) }} tez
              </span>
            </div>
          </div>
          <div class="mt-auto">
            <hr class="p-1">
            <button
              class="nes-btn is-primary"
              @click="$router.push(`/trades/${trade.owner}/${trade.tradeId}`)"
            >
              View Trade
            </button>
          </div>
        </div>
      </div>
      <Pagination 
        :offset="tradeOffset"
        :limit="tradeLimit"
        :pages="tradePages"
        @firstPage="firstPageTrades"
        @prevPage="prevPageTrades"
        @nextPage="nextPageTrades"
        @lastPage="lastPageTrades"
        @pageSizeSelected="pageSizeSelectedTrades"
      >
        <template #perPageOptions>
          <option value="4">
            4
          </option>
          <option value="12">
            12
          </option>
          <option value="20">
            20
          </option>
          <option value="40">
            40
          </option>
          <option value="60">
            60
          </option>
        </template>
      </Pagination>
    </div>

    <div
      v-if="auctions.length"
    >
      <h1 class="text-2xl text-center">
        Open Auctions
      </h1>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Auction
          v-for="auction in auctions"
          :key="auction.auctionId"
          :auction="auction"
          :hide-bidding="true"
          :show-link="true"
        />
      </div>
      <Pagination 
        :offset="auctionOffset"
        :limit="auctionLimit"
        :pages="auctionPages"
        @firstPage="firstPageAuctions"
        @prevPage="prevPageAuctions"
        @nextPage="nextPageAuctions"
        @lastPage="lastPageAuctions"
        @pageSizeSelected="pageSizeSelectedAuctions"
      >
        <template #perPageOptions>
          <option value="3">
            3
          </option>
          <option value="9">
            9
          </option>
          <option value="30">
            30
          </option>
        </template>
      </Pagination>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { find } from 'lodash'

import AddrIcon from '../components/AddrIcon'
import Auction from '../components/Auction'
import Pagination from '../components/Pagination'

export default {
  name: 'ViewCollection',
  components: {
    AddrIcon,
    Auction,
    Pagination,
  },
  data () {
    return {
      state: this.$root.$data.state,
      loading: false,
      tokens: [],
      tokensLoaded: false,
      sortBy: 'tokenId',
      sortDir: true,
      offset: 0,
      limit: 4,
      owner: '',
      alias: '',
      trades: [],
      totalTrades: 0,
      tradeLimit: 4,
      tradeOffset: 0,
      auctions: [],
      totalAuctions: 0,
      auctionLimit: 3,
      auctionOffset: 0,
    }
  },
  computed: {
    pages () {
      if (!this.tokens) {
        return 1
      }
      let tot = parseInt((this.tokens.length / this.limit).toFixed(0))
      if (tot < (this.tokens.length / this.limit)) { tot++ }
      return tot
    },
    tradePages () {
      if (!this.trades) {
        return 1
      }
      let tot = parseInt((this.totalTrades / this.tradeLimit).toFixed(0))
      if (tot < (this.totalTrades / this.tradeLimit)) { tot++ }
      return tot
    },
  },
  async mounted() {
    this.state.log('mounted ViewCollection', this.state.tokens, this.state.tokensLoaded)
    this.getId()
  },
  methods: {
    async pageSizeSelected (value) {
      this.limit = parseInt(value)
      this.offset = 0
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.tokens.length) {this.offset -= this.limit}
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {this.offset = 0}
      else {this.offset = offset}
    },
    firstPage() {
      this.offset = 0
    },
    lastPage() {
      this.offset = (this.pages - 1) * (this.limit)
    },
    async pageSizeSelectedTrades (value) {
      this.tradeLimit = parseInt(value)
      this.tradeOffset = 0
      this.getTrades()
    },
    nextPageTrades() {
      this.tradeOffset += this.tradeLimit
      if (this.tradeOffset >= this.tokens.length) {this.tradeOffset -= this.tradeLimit}
      this.getTrades()
    },
    prevPageTrades() {
      let offset = this.tradeOffset - this.tradeLimit
      if (offset < 0) {this.tradeOffset = 0}
      else {this.tradeOffset = offset}
      this.getTrades()
    },
    firstPageTrades() {
      this.tradeOffset = 0
      this.getTrades()
    },
    lastPageTrades() {
      this.tradeOffset = (this.tradePages - 1) * (this.tradeLimit)
      this.getTrades()
    },
    async pageSizeSelectedAuctions (value) {
      this.auctionLimit = parseInt(value)
      this.auctionOffset = 0
      this.getAuctions()
    },
    nextPageAuctions() {
      this.auctionOffset += this.auctionLimit
      if (this.auctionOffset >= this.tokens.length) {this.auctionOffset -= this.auctionLimit}
      this.getAuctions()
    },
    prevPageAuctions() {
      let offset = this.auctionOffset - this.auctionLimit
      if (offset < 0) {this.auctionOffset = 0}
      else {this.auctionOffset = offset}
      this.getAuctions()
    },
    firstPageAuctions() {
      this.auctionOffset = 0
      this.getAuctions()
    },
    lastPageAuctions() {
      this.auctionOffset = (this.auctionPages - 1) * (this.auctionLimit)
      this.getAuctions()
    },
    async getId () {
      try{
        if (this.$route.params.owner) {
          if (this.state.aliases.length === 0) {
            await new Promise((resolve) => setTimeout(resolve, 500))
            return this.getId()
          }
          this.owner = this.$route.params.owner
          const alias = find(this.state.aliases, (a) => a.address === this.owner)
          if (alias) this.alias = alias.alias
          this.loading = true
          const tokResp = await axios({
            method: 'post',
            url:'/api/myTokens', 
            data: {
              allTokens: this.state.allTokens,
              userAddress: this.$route.params.owner,
            },
          })
          
          tokResp.data.tokens.forEach((tok) => {
            this.tokens.push({ ...tok, upgrading: false })
          })

          this.sortTokens('rarity', false)
          this.tokensLoaded = true

          await this.getTrades()
          await this.getAuctions()

          this.loading = false
        } else {
          this.tokens.length = 0
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    async getTrades () {
      const resp = await axios({
        url:'/api/trades', 
        params: {
          limit: this.tradeLimit,
          offset: this.tradeOffset,
          owner: this.$route.params.owner,
        },
      })
      this.trades = resp.data.trades.map((trade) => {
        if(this.alias) {
          trade.alias = this.alias
        }
        return trade
      })
      this.totalTrades = resp.data.total
    },
    async getAuctions () {
      const resp = await axios({
        url:'/api/auctions', 
        params: {
          limit: this.auctionLimit,
          offset: this.auctionOffset,
          seller: this.$route.params.owner,
        },
      })
      this.auctions = resp.data.auctions.map((auction) => {
        if(this.alias) {
          auction.sellerAlias = this.alias
        }
        return auction
      })
      this.totalAuctions = resp.data.total
    },
    sortTokens (sortBy, dir) {
      this.sortDir = dir
      this.sortBy = sortBy
      
      this.tokens.sort((a, b) => {
        switch (sortBy) {
          case 'name':
            if (this.sortDir)
              return (''+a.metadata.name).localeCompare(b.metadata.name)
            return (''+b.metadata.name).localeCompare(a.metadata.name)
          case 'rarity':
            if (this.sortDir)
              return a.tokenId - b.tokenId
            return b.tokenId - a.tokenId
          case 'balance':
            if (this.sortDir)
              return a.balance - b.balance
            return b.balance - a.balance
          default:
            if (this.sortDir)
              return (a.tokenId % 1000) - (b.tokenId % 1000)
            return (b.tokenId % 1000) - (a.tokenId % 1000)
        }
      })
      
    },
  },
}
</script>
