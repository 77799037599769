export default (state) => {
  const links = [
    {
      label: 'About',
      path: '/about',
      wallet: false,
    },
    {
      label: 'Roadmap',
      path: '/roadmap',
      wallet: false,
    },
    {
      label: 'Getting Started',
      path: '/',
      wallet: false,
    },
    {
      label: 'Claim',
      path: '/claim',
      wallet: true,
    },
    {
      label: 'Me',
      path: '/me',
      wallet: true,
    },
    {
      label: 'Market',
      path: '/market',
      wallet: true,
    },
    {
      label: 'Stats',
      path: '/stats',
      wallet: false,
    },
    {
      label: 'Treasury',
      path: '/treasury',
      wallet: false,
    },
    {
      label: 'Leaders',
      path: '/leaderboard',
      wallet: false,
    },
  ]
  return links.filter(link => {
    if (!state.userAddress) {
      return !link.wallet
    }
    return link.path !== '/'
  })
}