<template>
  <transition :duration="300">
    <section
      v-show="isOpen"
      class="fixed inset-0 overflow-hidden z-50"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="absolute inset-0 overflow-hidden">
        <transition
          enter-active-class="ease-in-out duration-500"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in-out duration-500"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="isOpen"
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
            @click="toggleOpen"
          />
        </transition>

        <div class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <transition
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div
              v-show="isOpen"
              class="relative w-screen max-w-md"
            >
              <transition
                enter-active-class="ease-in-out duration-500"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in-out duration-500"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-show="isOpen"
                  class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                >
                  <button
                    class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    @click="toggleOpen"
                  >
                    <span class="sr-only">Close panel</span>
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </transition>

              <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div class="px-4 sm:px-6">
                  <h2
                    id="slide-over-title"
                    class="text-lg font-medium text-gray-900"
                  >
                    Create Offer
                  </h2>
                </div>
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <div class="absolute inset-0 px-4 sm:px-6">
                    <div
                      class="h-full border-2 border-dashed border-gray-200 text-left"
                      aria-hidden="true"
                    > 
                      <div class="bg-indigo-100 border-b-2 border-dashed">
                        <label
                          for="trade_type_select"
                          class="block font-medium text-gray-700 px-2 pt-2"
                        >I want to...</label>
                        <div class="mx-2 mb-2">
                          <VueMultiselect
                            v-model="tradeTypeSelect"
                            :options="tradeTypeOptions"
                            :show-labels="false"
                            @select="onTradeTypeChange"
                            @remove="onTradeTypeChange"
                          />
                        </div>
                        <hr>
                      </div>
                      <div class="bg-indigo-200">
                        <span v-if="tradeType == 'Tokens'">
                          <label
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            I'll give these tokens... <span class="text-xs">(4 max)</span>
                          </label>
                          <div
                            v-for="(val, key) in trade.offered"
                            :key="key"
                            class="p-2 text-sm sm:text-base"
                          >
                            <button
                              @click="addOfferedToken(val.token)"
                            >
                              <fa 
                                icon="plus-circle" 
                                class="h-5 w-5 mr-1"
                              />
                            </button><span class="">{{ val.amt }}</span><button
                              @click="removeOfferedToken(val.token)"
                            >
                              <fa 
                                icon="minus-circle" 
                                class="h-5 w-5 ml-1"
                              />
                            </button>
                        
                            {{ val.token.metadata.shortName }}<span class="text-gray-500">({{ val.token.rarity }})</span><button
                              @click="removeOfferedToken(val.token, val.amt)"
                            >
                              <fa 
                                icon="times-circle" 
                                class="h-5 w-5"
                              />
                            </button>
                          </div>
                          <trade-dropdown
                            class="px-2 py-2"
                            :tokens="availableTokens"
                            :item-selected="addOfferedToken"
                          />
                        </span>
                      
                        <span v-if="tradeType == 'XTZ'">
                          <label
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            I'll give this much tez...
                          </label>
                          <div class="mt-1 flex rounded-md shadow-sm px-2 pt-2">
                            <input
                              v-model="trade.price_offered"
                              type="number"
                              step="0.25"
                              min="0"
                              class="p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none  sm:text-sm border-gray-300 border-solid"
                            >
                          </div>
                        </span>
                        <span v-if="tradeType == 'XTZ' || subTradeType == 'Swap'">
                          <label
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            For these tokens...<span class="text-xs">(4 max)</span>
                          </label>
                          <div
                            v-for="(val, key) in trade.accepted"
                            :key="key"
                            class="p-2 text-sm sm:text-base"
                          >
                            <button
                              @click="addAcceptedToken(val.token)"
                            >
                              <fa 
                                icon="plus-circle" 
                                class="h-5 w-5 mr-1"
                              />
                            </button><span class="">{{ val.amt }}</span><button
                              @click="removeAcceptedToken(val.token)"
                            >
                              <fa 
                                icon="minus-circle" 
                                class="h-5 w-5 ml-1"
                              />
                            </button>
                        
                            {{ val.token.metadata.shortName }}<span class="text-gray-500">({{ val.token.rarity }})</span><button
                              @click="removeAcceptedToken(val.token, val.amt)"
                            >
                              <fa 
                                icon="times-circle" 
                                class="h-5 w-5"
                              />
                            </button>
                          </div>
                          <trade-dropdown
                            class="px-2 pt-2 pb-2"
                            :tokens="allTokens"
                            :item-selected="addAcceptedToken"
                          />
                        </span>
                        <span v-if="tradeType == 'Tokens' && subTradeType == 'Swap'">
                          <label
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            OR for this much tez...
                          </label>
                          <div class="mt-1 flex rounded-md shadow-sm px-2 pt-2 pb-2">
                            <input
                              v-model="trade.price_accepted"
                              type="number"
                              step="0.25"
                              min="0"
                              class="p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none  sm:text-sm border-gray-300 border-solid"
                            >
                          </div>
                        </span>
                      </div>
                      <div class="bg-indigo-300">
                        <span v-if="tradeType == 'Tokens' && subTradeType == 'Auction'">
                          <label
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            For starting bid of...
                          </label>
                          <div class="mt-1 flex rounded-md shadow-sm px-2 pt-2">
                            <input
                              v-model="trade.bid_start"
                              type="number"
                              step="0.1"
                              min="0.1"
                              class="p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none  sm:text-sm border-gray-300 border-solid"
                            > <span class="ml-1 mt-3 text-xs">xtz</span>
                          </div>
                          <label
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            {{ trade.dutch ? 'Decreasing by...' : 'With min bid increase of...' }}
                          </label>
                          <div class="mt-1 flex rounded-md shadow-sm px-2 pt-2">
                            <input
                              v-model="trade.bid_step"
                              type="number"
                              step="0.1"
                              min="0.1"
                              class="p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none  sm:text-sm border-gray-300 border-solid"
                            >  <span class="ml-1 mt-3 text-xs">xtz</span>
                          </div>
                          <span v-if="!trade.dutch">
                            <label
                              id="listbox-label"
                              class="block text-sm font-medium text-gray-700 px-2 pt-2"
                            >
                              With auction length of...
                            </label>
                            <div
                              class="mt-1 flex rounded-md shadow-sm px-2 pt-2 "
                            >
                              <input
                                v-model="trade.days"
                                type="number"
                                step="1"
                                min="0"
                                max="7"
                                class="text-center p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-none sm:text-sm border-gray-300 border-solid"
                                style="max-width: 100px;"
                              > <span class="ml-1 mt-3 text-xs">days</span>
                              <input
                                v-model="trade.hours"
                                type="number"
                                step="1"
                                min="0"
                                :max="trade.days < 7 ? 23 : 0"
                                class="text-center ml-6 p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-none sm:text-sm border-gray-300 border-solid"
                                style="max-width: 100px;"
                              > <span class="ml-1 mt-3 text-xs">hours</span>
                            </div>
                            <label
                              id="listbox-label"
                              class="block text-sm font-medium text-gray-700 px-2 pt-2"
                            >
                              And an optional Fixed Price of...
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm px-2 py-2">
                              <input
                                v-model="trade.buy_now"
                                type="number"
                                step="0.25"
                                min="0"
                                class="p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none  sm:text-sm border-gray-300 border-solid"
                              >  <span class="ml-1 mt-3 text-xs">xtz</span>
                            </div>
                          </span>
                          <label
                            v-if="trade.dutch"
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            Every...
                          </label>
                          <div
                            v-if="trade.dutch"
                            class="mt-1 flex rounded-md shadow-sm px-2 py-2"
                          >
                            <input
                              v-model="trade.round_len"
                              type="number"
                              step="1"
                              min="5"
                              class="text-left p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-none  sm:text-sm border-gray-300 border-solid"
                            > <span class="ml-1 mt-3 text-xs">mins</span>
                          </div>
                          <label
                            v-if="trade.dutch"
                            id="listbox-label"
                            class="block text-sm font-medium text-gray-700 px-2 pt-2"
                          >
                            And reserve price of...
                          </label>
                          <div
                            v-if="trade.dutch"
                            class="mt-1 flex rounded-md shadow-sm px-2 py-2"
                          >
                            <input
                              v-model="trade.reserve"
                              type="number"
                              step="0.1"
                              min="0.1"
                              class="p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none  sm:text-sm border-gray-300 border-solid"
                            >  <span class="ml-1 mt-3 text-xs">xtz</span>
                          </div>
                        </span>
                      </div>
                      <!-- <hr>
                      <div class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          id="togglePublicTrade"
                          v-model="isPublicTrade"
                          type="checkbox"
                          name="togglePublicTrade"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                        >
                        <label
                          for="togglePublicTrade"
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                        />
                      </div>
                      <label
                        for="togglePublicTrade"
                        class="text-xs text-gray-700 inline"
                      >This trade is {{ isPublicTrade ? 'public' : 'unlisted' }}</label> -->
                      <button
                        class="text-sm uppercase w-full py-4 px-6 nes-btn is-primary h-20"
                        :disabled="loading"
                        @click="offerTrade"
                      >
                        <span v-if="loading">
                          <fa 
                            icon="cog" 
                            class="animate-spin h-5 w-5 mr-3"
                          />
                          Creating offers... Pls wait up to 1 min for block confirmation.
                        </span>
                        <span v-if="!loading && batchTrades.length == 0">Create Offer</span>
                        <span v-else-if="!loading">Create {{ batchTrades.length }} Offer(s)</span>
                      </button>
                      <div
                        v-if="!loading"
                        class="mt-2"
                      >
                        <button
                          class="text-sm uppercase w-full py-4 px-6 nes-btn h-10 mt-5"
                          @click="addToBatch"
                        >
                          Add to batch
                        </button>
                      </div>
                      <div
                        v-if="batchTrades.length > 0 && !loading"
                        class="mt-2"
                      >
                        <button
                          class="text-sm uppercase w-full py-4 px-6 nes-btn h-10 mt-5 is-error"
                          @click="()=>{batchTrades.length = 0}"
                        >
                          Clear batch
                        </button>
                      </div>
                      <div
                        v-if="loading"
                        class="w-full p-2"
                      >
                        <img
                          :src="nextGif"
                          class="m-auto"
                        >
                      </div>
                      <div class="text-xs mt-5 px-2 text-gray-500">
                        While pure barter trades are free, a 2.5% fee is incurred on XTZ sales.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { OpKind } from '@taquito/taquito'
// import axios from 'axios'
import { findIndex,map } from 'lodash'
import moment from 'moment'
import VueMultiselect from 'vue-multiselect'

import TradeDropdown from '../components/TradeDropdown.vue'
/* eslint-disable vue/require-default-prop */

export default {
  name: 'OfferTrade',
  components: { TradeDropdown,VueMultiselect },
  props: {
      allTokens: Array,
      myTokens: Array,
      isOpen: Boolean,
      tradingFee: Number,
      toggleOpen: Function,
      refresh: Function,
  },
  data () {
    return {
      state: this.$root.$data.state,
      loading: false,
      nextGif: null,
      tradeType: 'Tokens',
      subTradeType: 'Swap',
      tradeTypeSelect: 'Trade my tokens',
      tradeTypeOptions: ['Trade my tokens', 'Offer XTZ for tokens', 'Create Highest Bid Auction', 'Create Price Drop Auction'],
      isPublicTrade: true,
      batchTrades: [],
      trade: {
        offered: {},
        accepted: {},
        price_accepted: 0.0,
        price_offered: 0.0,
        bid_start: 0.5,
        bid_step: 0.5,
        buy_now: 0.0,
        days: 1,
        hours: 0,
        round_len: 60,
        reserve: 0.5,
        dutch: true,
      },
    }
  },
  computed: {
    availableTokens () {
      let tokenCopy = this.myTokens.map(token => {return { ...token }})
      tokenCopy = tokenCopy.map(token => {
        for (const t of this.batchTrades) {
          if (t.offered[token.tokenId]) {
            token.balance -= t.offered[token.tokenId].amt
          }
        }
        if (this.trade.offered[token.tokenId]) {
          token.balance -= this.trade.offered[token.tokenId].amt
        }
        return token
      })
      tokenCopy = tokenCopy.filter(token => token.balance > 0)
      tokenCopy.sort((a, b) => (''+a.metadata.shortName).localeCompare(b.metadata.shortName))
      return tokenCopy
    },
  },
  methods: {
    resetTrade () {
      this.isPublicTrade = true
      this.trade.offered = {}
      this.trade.accepted = {}
      this.trade.price_accepted = 0.0
      this.trade.price_offered = 0.0
      this.trade.bid_start = 0.5
      this.trade.bid_step = 0.5
      this.trade.buy_now = 0.0
      this.trade.days = 1
      this.trade.hours = 0
      this.trade.round_len = 60
      this.trade.reserve = 0.5
      this.trade.dutch = true
    },
    async onTradeTypeChange () {
      await new Promise((resolve) => setTimeout(resolve, 150))
      this.resetTrade()
      switch(this.tradeTypeSelect) {
        case 'Offer XTZ for tokens':
          this.tradeType = 'XTZ'
          this.subTradeType = 'Swap'
          break
        case 'Create Highest Bid Auction':
          this.tradeType = 'Tokens'
          this.subTradeType = 'Auction'
          this.trade.dutch = false
          break
        case 'Create Price Drop Auction':
          this.tradeType = 'Tokens'
          this.subTradeType = 'Auction'
          this.trade.dutch = true
          break
        default:
          this.tradeTypeSelect = 'Trade my tokens'
          this.tradeType = 'Tokens'
          this.subTradeType = 'Swap'
          break
      }
    },
    addToBatch () {
      if (Object.keys(this.trade.offered).length === 0 && Number(this.trade.price_offered) <= 0) {
        alert("You must offer something in trade.")
        return
      }
      if (this.subTradeType == 'Auction' && (Number(this.trade.bid_start) < .1 || Number(this.trade.bid_step) < .1)) {
        alert("You must have minimum bid start and step of .1xtz")
        return
      } else if (this.subTradeType != 'Auction' && Object.keys(this.trade.accepted).length === 0 && Number(this.trade.price_accepted) <= 0) {
        alert("You must accept something in trade.")
        return
      }
      this.trade.isAuction = this.tradeType == 'Tokens' && this.subTradeType == 'Auction'
      this.batchTrades.push(JSON.parse(JSON.stringify(this.trade)))
      this.trade.offered = {}
      this.trade.price_accepted = 0.0
    },
    async offerTrade () {
      // this.state.log(this.trade)
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      try{
        let operators = []
        let transactions = []

        if (this.batchTrades.length == 0) {
          this.addToBatch()
        }
        
        this.loading = true

        for (let trade of this.batchTrades) {
          const price_accepted = this.isAuction ? 0 : this.state.convertToMutez(parseFloat(Number(trade.price_accepted).toFixed(6)))
          const price_offered = this.isAuction ? 0 : this.state.convertToMutez(parseFloat(Number(trade.price_offered).toFixed(6)))
          const bid_start = this.state.convertToMutez(parseFloat(Number(trade.bid_start).toFixed(6)))
          const bid_step = this.state.convertToMutez(parseFloat(Number(trade.bid_step).toFixed(6)))
          const reserve = this.state.convertToMutez(parseFloat(Number(trade.reserve).toFixed(6)))
          const buy_now = this.state.convertToMutez(parseFloat(Number(trade.buy_now).toFixed(6)))
          const round_len = trade.round_len * 60 // convert to seconds
          let end_timestamp = moment().utc().add(trade.days, 'days').add(trade.hours, 'hours')
          if (trade.days < 7) {
            end_timestamp = end_timestamp.add(10, 'minutes')
          }
          end_timestamp = end_timestamp.toISOString()
          if (trade.dutch) {
            end_timestamp = moment().utc().add(7, 'days').toISOString()
          }

          const offered = map(trade.offered, (val)=>{
            operators.push({ 
              add_operator: { 
                owner: this.state.userAddress,
                operator: trade.isAuction ? process.env.VUE_APP_PP_AUCTION : process.env.VUE_APP_PP_MARKET,
                token_id: val.token.tokenId,
              },
            })
            return { amount: val.amt, fa2: process.env.VUE_APP_PP_MAIN, token_id: val.token.tokenId }
          })
          const accepted = map(trade.accepted, (val)=>{
            return { amount: val.amt, fa2: process.env.VUE_APP_PP_MAIN, token_id: val.token.tokenId }
          })

          const opts = {}
          if (price_offered > 0) {
            opts.amount = price_offered
            opts.mutez = true
          }

          this.state.log(price_accepted, price_offered, offered, accepted, operators, bid_start, bid_step, end_timestamp)
          if (trade.isAuction) {
            transactions.push({
                  kind: OpKind.TRANSACTION,
                  ... this.state.ppAuction.methods.create_auction(bid_start, bid_step, buy_now, trade.dutch, end_timestamp, reserve, round_len, offered).toTransferParams(),
                  ...opts,
                })
          } else {
            transactions.push({
                  kind: OpKind.TRANSACTION,
                  ... this.state.ppMarket.methods.offer_trade(price_accepted, price_offered, accepted, offered).toTransferParams(),
                  ...opts,
                })
          }
        }
        if (operators.length > 0) {
          transactions = [{
                kind: OpKind.TRANSACTION,
                ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
              }].concat(transactions)
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)
        
        const bt = [...this.batchTrades]
        this.batchTrades.length = 0
        this.onTradeTypeChange()

        for (let i = 0; i < bt.length; i++) {
          const trade = bt[i]
          const offered = trade.isAuction ? trade.tokens : trade.offered
          map(offered, (val)=>{
            const idx = findIndex(this.state.myTokens, mt => mt.tokenId === val.token.tokenId)
            if (idx > -1) {
              this.state.myTokens[idx].balance -= val.amount
            }
            this.state.sortTokens(this.state.sortBy, true)
            return { amount: val.amt, ...find(this.state.allTokens, (tok) => {
                return tok.tokenId === val.token.tokenId
              }), 
            }
          })
        }

        await this.refresh()
        this.loading = false
        this.toggleOpen()

        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)
      } catch(e) {
        this.loading = false
        this.batchTrades.length = 0
        this.resetTrade()
        console.error('Unable to offer trade', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async addOfferedToken (token) { 
      if (this.trade.offered[token.tokenId]) {
        const idx = findIndex(this.availableTokens, mt => mt.tokenId === token.tokenId)
        if (idx > -1) {
          if(this.availableTokens[idx].balance){
            this.trade.offered[token.tokenId].amt++
          }
        }
      } else if (Object.keys(this.trade.offered).length > 3) {
        return
      } else {
        this.trade.offered[token.tokenId] = { token, amt:1 }
      }
    },
    async addAcceptedToken (token) {
      if (this.trade.accepted[token.tokenId]) {
        this.trade.accepted[token.tokenId].amt++
      } else if (Object.keys(this.trade.accepted).length > 3) {
        return
      } else {
        this.trade.accepted[token.tokenId] = { token, amt:1 }
      }
    },
    async removeOfferedToken (token, amt = 1) {
      if (this.trade.offered[token.tokenId]) {
        this.trade.offered[token.tokenId].amt-=amt
        if (this.trade.offered[token.tokenId].amt === 0) {
          delete this.trade.offered[token.tokenId]
        }
      }
    },
    async removeAcceptedToken (token, amt = 1) {
      if (this.trade.accepted[token.tokenId]) {
        this.trade.accepted[token.tokenId].amt-=amt
        if (this.trade.accepted[token.tokenId].amt === 0) {
          delete this.trade.accepted[token.tokenId]
        }
      }
    },
  },
}
</script>
