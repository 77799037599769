<template>
  <div>
    <div class="grid grid-cols-12 items-right pb-3 pt-3">  
      <div class="col-span-12 treasure p-3 md:-mt-10 border-2 border-color-gray border-dashed">
        <span class="text-xs">
          Treasury: <span class="text-green-700"><br>{{ getBalance }}tez</span>
        </span>
        <!-- <span class="text-xs">
          Next Payout: <span class="text-green-700"><br>{{ getNextPayout }}tez</span>
        </span> -->
        <span class="text-xs">
          Claiming period resets: <span class="text-green-700"><br>{{ state_root.untilNextPeriod.fromNow() }}</span>
        </span>
        <span class="text-xs">
          Claims today: <span class="text-green-700"><br>{{ state_root.totalClaims }} of {{ state_root.maxClaimsPerPeriod }} claimed</span>
        </span>
        <span class="text-xs">
          Total Wallets: <span class="text-green-700"><br>{{ state_root.usersCount }}</span>
        </span>
      </div>
    </div>
    <div class="grid grid-cols-6 gap-2 items-center justify-between">
      <div>
        <router-link to="/">
          <img 
            src="../assets/eagle-right.png" 
            :class="{'animate-pulse': state.loading}"
            width="100"
          >
        </router-link>
      </div>
      <div class="col-span-4 flex flex-col items-center">
        <h1 class="text-2xl md:text-4xl lg:text-5xl my-0">
          <router-link
            to="/"
            class="text-black hover:text-black"
          >
            PiXEL POTUS
          </router-link>
        </h1>
        <p
          v-if="!state.userAddress && state.agreedToDisclaimer"
          class="text-xs hidden md:inline-block"
        >
          🇺🇸🇺🇸🇺🇸🇺🇸<a 
            href="#"
            class="text-blue-500 hover:text-blue-600 cursor-pointer"
            @click="connectWallet"
          >
            Connect Wallet
          </a>🇺🇸🇺🇸🇺🇸🇺🇸
        </p>
        <p 
          v-if="state.userAddress"
          class="text-xs hidden md:inline-block breakwords"
        >
          Connected to <AddrIcon
            :address="state.userAddress"
            :alias="state.userAlias"
            :show-disconnect="true"
            :is-pill="true"
            :link-to-collection="true"
          /> ({{ state.convertFromMutez(state.balance) }}ꜩ)
        </p>
      </div>
      <div>
        <router-link to="/">
          <img 
            class="float-right"
            :class="{'animate-pulse': state.loading}"
            src="../assets/eagle.png" 
            width="100"
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import AddrIcon from './AddrIcon.vue'


export default {
  name: 'Crest',
  components: {
    AddrIcon,
  },
  props: {
    state: {
      type: Object,
      default: () => { return {}},
    },
    connectWallet: { 
      type: Function,
      default: () => { return {}},
    },
  },
   data () {
    return {
      state_root: this.$root.$data.state,
      timer : "",
    }
  },
  computed: {
    getBalance () {
      return this.state_root.convertFromMutez(this.state_root.treasureBalance)
    },
    getNextPayout () {
       return this.state_root.convertFromMutez(this.state_root.treasureBalance * (this.state_root.treasurePayoutPercent/100))
    },
  },
  created() {
    this.state_root.getTreasure()
    this.state_root.getClaimStorage()
    this.state_root.getUsersCount()
    setInterval(() => {
         this.state_root.getTreasure()
         this.state_root.getClaimStorage()
         this.state_root.getUsersCount()
    }, 120000)
  },
  methods: {
    async disconnect () {
      try{
        await this.state.disconnect()
        this.$router.push("/")
      } catch(e) {
        console.error('Unable to disconnect wallet', e)
      }
    },
  },
}
</script>

<style>

.treasure {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.treasure > span {
  font-size: 10px !important;
}
 
@media (min-width: 40em) {
  .treasure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

</style>