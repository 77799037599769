<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-2 items-center justify-between text-left mt-12 overflow-hidden">
    <h1 class="text-2xl text-center">
      <span
        v-if="loading"
        class="align-top"
      >
        <fa 
          icon="cog" 
          class="animate-spin mr-2 mb-1"
        />
      </span>Market
    </h1>

    <SpeechBubble
      :img="state.images.trump"
      :show="showHelp"
      :potus="'Pres. Trump'"
    >
      Here you can make deals with other constituents. "Current Offers" is a list of all open trades from others. "My Open Offers" is a list of your own open trades. Click "Create Offer" to offer a new trade. You can trade for other POTUSes or sell for tez. Trading is free, while selling incurs a tax of {{ tradingFee * 100 }}%. Your open trade tokens are held in escrow, and will not appear in your collection. You can cancel your trades at any time to return them to your balance. Get it? <br><a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        @click="toggleHelp"
      >Got it</a>
    </SpeechBubble>

    <div
      v-if="trading"
      class="text-center"
    >
      <fa 
        icon="cog" 
        class="animate-spin h-5 w-5 mr-3"
      />Please wait up to 1 minute for block confirmation.
    </div>
    
    <SpeechBubble
      :img="state.images.trump"
      :show="confirmation"
      :potus="'Trump'"
    >
      <div
        class="text-base"
      >
        Trade {{ tradeCompleteText }}! Check out your shiny new POTUSes in <router-link
          class=""
          to="/me"
        >
          your collection.
        </router-link>
      </div>
    </SpeechBubble>

    <div
      v-if="!trading"
      class="grid grid-cols-1 text-center sm:text-center sm:grid-cols-3 md:grid-cols-4"
    >
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600 my-2"
        :class="{'underline text-gray-900': viewAllTrades}"
        @click="changeToView('viewAllTrades');getTrades()"
      >Trades</a>
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600 my-2"
        :class="{'underline text-gray-900': viewAuctions}"
        @click="changeToView('viewAuctions');"
      >Auctions</a>
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600 my-2"
        :class="{'underline text-gray-900': viewHistory}"
        @click="changeToView('viewHistory');loadMoreHistory()"
      >History</a>
      <button
        class="sm:text-right nes-btn is-primary"
        @click="isOpen = !isOpen"
      >
        Create Offer
      </button>
    </div>
    
    <div
      v-if="viewAllTrades && !trading"
      class="p-5 nes-container is-rounded"
    >
      <label
        for="filter_trades"
        class="block text-lg font-medium text-gray-700"
      >Filters
        <button @click="showFilters = !showFilters">
          <fa 
            v-if="!showFilters"
            icon="chevron-right" 
            class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
          />
          <fa 
            v-if="showFilters"
            icon="chevron-down" 
            class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
          />
        </button>
        <button
          class="float-right text-blue-link hover:text-blue-hov text-xs"
          @click="clearFilters"
        >
          reset
        </button>
      </label>
      <span v-show="showFilters">
        <input
          id="filter_trades"
          v-model="filter"
          type="text"
          name="filter_trades"
          class="w-full p-2 border-2 mt-1 focus:ring-blue-500 focus:border-blue-500 inline-block shadow-sm text-xs sm:text-sm border-gray-300 rounded-none w-full md:w-3/5 xl:w-1/3 "
          placeholder="potus name or token id"
          @input="updateFilters()"
        >
        <div class="relative inline-block w-10 mt-2 mr-2 ml-2 align-middle select-none transition duration-200 ease-in">
          <input
            id="toggleApplyToOffering"
            v-model="applyToOffering"
            type="checkbox"
            name="toggleApplyToOffering"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @input="updateCheckbox($event)"
          >
          <label
            for="toggleApplyToOffering"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          />
        </div>
        <label
          for="toggleApplyToOffering"
          class="text-xs text-gray-700 inline cursor-pointer"
        >Apply to Offering</label>
        <div class="relative inline-block w-10 mt-2 mr-2 ml-2 align-middle select-none transition duration-200 ease-in">
          <input
            id="toggleApplyToAccepting"
            v-model="applyToAccepting"
            type="checkbox"
            name="toggleApplyToAccepting"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @input="updateCheckbox($event)"
          >
          <label
            for="toggleApplyToAccepting"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          />
        </div>
        <label
          for="toggleApplyToAccepting"
          class="text-xs text-gray-700 inline cursor-pointer"
        >Apply to Accepting</label>
        <hr>
        <div class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <input
            id="toggleOnlyShowMyOpenOffers"
            v-model="onlyShowMyOpenOffers"
            type="checkbox"
            name="toggleOnlyShowMyOpenOffers"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @input="updateCheckbox()"
          >
          <label
            for="toggleOnlyShowMyOpenOffers"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          />
        </div>
        <label
          for="toggleOnlyShowMyOpenOffers"
          class="text-xs text-gray-700 inline cursor-pointer"
        >Only show my offers</label>
        <hr>
        <div class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <input
            id="toggleOnlyTradesICanTake"
            v-model="onlyTradesICanTake"
            type="checkbox"
            name="toggleOnlyTradesICanTake"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @input="updateCheckbox()"
          >
          <label
            for="toggleOnlyTradesICanTake"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          />
        </div>
        <label
          for="toggleOnlyTradesICanTake"
          class="text-xs text-gray-700 inline cursor-pointer"
        >Only show trades I can take</label>

        <hr>
        <label
          for="tradeTypesOptions"
          class="text-sm font-medium text-gray-700"
        >Offer Types:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="tradeTypes"
            :options="tradeTypesOptions"
            :multiple="true"
            :show-labels="false"
            @select="tradeTypesSelected"
            @remove="tradeTypesSelected"
          />
        </div>

        <hr>
        <label
          for="tradeTypesOptions"
          class="text-sm font-medium text-gray-700"
        >Sorted by:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="sortedBy"
            :options="sortedByOptions"
            :show-labels="false"
            @select="sortedBySelected"
            @remove="sortedBySelected"
          />
        </div>
        <hr>
        <label
          class="text-sm font-medium text-gray-700"
        >Rarities:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="rarities"
            :options="raritiesOptions"
            :multiple="true"
            :show-labels="false"
            placeholder="Select Rarities"
            @select="tradeTypesSelected"
            @remove="tradeTypesSelected"
          />
        </div>
        <hr>
        <div class="flex flex-cols">
          <label
            class="text-sm font-medium text-gray-700 mt-5"
          >Price Range:</label>
          <div class="mt-12 mr-2 w-full">
            <Slider
              v-model="askingPriceRange"
              :max="askingPriceRangeMax"
              :format="priceFormat"
              :merge="1.3"
              :step=".01"
              class="slider-blue"
              @change="tradeTypesSelected"
            />
          </div>
        </div>
      </span>
    </div>
    
    <div
      v-if="!trading && !loading && viewAllTrades"
      class="flex flex-col"
    >
      <div class="hidden sm:inline -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <Pagination 
              :offset="offset"
              :limit="limit"
              :pages="pages"
              @firstPage="firstPage"
              @prevPage="prevPage"
              @nextPage="nextPage"
              @lastPage="lastPage"
              @pageSizeSelected="pageSizeSelected"
            >
              <template #perPageOptions>
                <option value="5">
                  5
                </option>
                <option value="10">
                  10
                </option>
                <option value="20">
                  20
                </option>
                <option value="50">
                  50
                </option>
                <option value="100">
                  100
                </option>
              </template>
            </Pagination>
            <table class="min-w-full divide-y divide-gray-200 table-auto">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Offering
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Accepting
                  </th>
                  <th
                    scope="col"
                    class="relative px-6 py-3 w-1/4"
                  >
                    <span v-if="onlyShowMyOpenOffers">
                      <button
                        v-if="!batchSelect"
                        class="nes-btn is-primary text-xs"
                        @click="batchSelect = true"
                      >
                        Start Batch Cancel
                      </button>
                      <button
                        v-if="batchSelect && !upgrading"
                        class="nes-btn is-primary text-xs"
                        @click="cancelTrade(selectedTrades)"
                      >
                        Cancel {{ selectedTrades.length }}
                      </button>
                      <button
                        v-if="batchSelect && !upgrading"
                        class="nes-btn is-normal text-xs"
                        @click="batchSelect = false; selectedTrades.length = 0"
                      >
                        Stop
                      </button>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">             
                <tr
                  v-for="(trade, tradekey) in trades"
                  v-show="!onlyTradesICanTake || (onlyTradesICanTake && trade.canTrade)"
                  :key="tradekey"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex flex-col items-left">
                      <span class="text-xs"><AddrIcon
                        :address="trade.owner"
                        :alias="trade.alias"
                      /> will give...</span>
                      <div
                        v-if="trade.price_offered > 0"
                        class="text-sm font-medium text-gray-900 p-1"
                      >
                        <span
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                        >
                          {{ state.convertFromMutez(trade.price_offered) }} tez
                        </span>
                      </div>
                      <div
                        v-for="(tok, okey) in trade.offered"
                        :key="okey"
                        class="text-sm font-medium text-gray-900 p-1"
                      >
                        <span
                          v-if="okey > 0"
                          class="mb-5 text-gray-500"
                        >
                          and
                        </span>
                        <div class="flex items-center">
                          <div class="flex-shrink-0 w-10">
                            <img
                              class="w-10 squared-full"
                              :src="tok.metadata.displayUri"
                              alt=""
                            >
                          </div>
                          <div class="ml-4">
                            <div class="text-sm font-medium text-gray-900">
                              {{ tok.metadata.name }}
                            </div>
                            <div class="text-sm text-gray-500">
                              {{ tok.metadata.rarity }} x{{ tok.amount }}
                            </div>
                            <div class="text-sm text-gray-500">
                              Token ID: {{ tok.tokenId }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex flex-col items-left">
                      <span class="text-xs">for...</span>
                      <div
                        v-for="(tok, okey) in trade.accepted"
                        :key="okey"
                        class="text-sm font-medium text-gray-900 p-1"
                      >
                        <span
                          v-if="okey > 0"
                          class="mb-5 text-gray-500"
                        >
                          and
                        </span>
                        <div class="flex items-center">
                          <div class="flex-shrink-0 w-10">
                            <img
                              class="w-10 squared-full"
                              :src="tok.metadata.displayUri"
                              alt=""
                            >
                          </div>
                          <div class="ml-4">
                            <div class="text-sm font-medium text-gray-900">
                              {{ tok.metadata.name }}
                            </div>
                            <div class="text-sm text-gray-500">
                              {{ tok.metadata.rarity }} x{{ tok.amount }}
                            </div>
                            <div class="text-sm text-gray-500">
                              Token ID: {{ tok.tokenId }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="trade.price_accepted > 0"
                        class="text-sm font-medium text-gray-900 p-1"
                      >
                        <span v-if="trade.price_accepted && trade.accepted.length > 0">or</span>
                        <span
                          v-if="trade.price_accepted"
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                        >
                          {{ state.convertFromMutez(trade.price_accepted) }} tez
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <div class="grid grid-cols-2">
                      <div
                        v-if="viewAllTrades"
                        class="col-span-2"
                      >
                        <span v-if="trade.owner != state.userAddress">
                          <button
                            v-if="trade.accepted.length > 0"
                            :disabled="!trade.canTrade"
                            class="text-left text-blue-link hover:text-blue-hov"
                            @click="takeTrade(trade)"
                          >
                            <span>
                              <span
                                v-if="trade.canTrade"
                              >
                                Trade
                              </span>
                              <span
                                v-else
                                class="line-through text-gray-500 hover:text-gray-500"
                                title="You don't have the required potus to trade."
                              >
                                Trade
                              </span>
                            </span>
                          </button>
                          <span v-if="trade.price_accepted > 0 && trade.accepted.length > 0"> or </span>
                          <button
                            v-if="trade.price_accepted > 0"
                            class="text-left text-blue-link hover:text-blue-hov"
                            @click="takeTrade(trade, true)"
                          >
                            <span>
                              Buy
                            </span>
                          </button>
                        </span>
                        <button
                          v-if="trade.owner == state.userAddress && !batchSelect"
                          class="text-left text-blue-link hover:text-blue-hov"
                          @click="cancelTrade(trade)"
                        >
                          Cancel
                        </button>
                        <br>
                        <button
                          v-if="!batchSelect"
                          class="text-left text-blue-link hover:text-blue-hov"
                          @click="doCopy(trade)"
                        >
                          <span>
                            Copy Link
                          </span>
                        </button>
                        <a
                          :href="`https://www.pixelpotus.com/trades/${trade.owner}/${trade.tradeId}`"
                          target="_blank"
                          class="mr-3"
                        ><fa 
                          icon="external-link-alt" 
                          class="ml-1 align-baseline"
                        /></a>
                        <div v-if="batchSelect">
                          <div
                            class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in"
                          >
                            <input
                              type="checkbox"
                              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                              @input="(e) => selectForBatch(e, trade)"
                            >
                            <label
                              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            /> 
                          </div>
                          <label
                            class="text-xs text-gray-700 inline"
                          >Cancel?</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="viewAllTrades && trades.length === 0"
              class="m-auto w-full text-center p-5"
            >
              Nothing to see here.
            </div>
            <Pagination 
              :offset="offset"
              :limit="limit"
              :pages="pages"
              @firstPage="firstPage"
              @prevPage="prevPage"
              @nextPage="nextPage"
              @lastPage="lastPage"
              @pageSizeSelected="pageSizeSelected"
            >
              <template #perPageOptions>
                <option value="5">
                  5
                </option>
                <option value="10">
                  10
                </option>
                <option value="20">
                  20
                </option>
                <option value="50">
                  50
                </option>
                <option value="100">
                  100
                </option>
              </template>
            </Pagination>
          </div>
        </div>
      </div>
      <div class="sm:hidden flex flex-col gap-2">
        <Pagination 
          :offset="offset"
          :limit="limit"
          :pages="pages"
          @firstPage="firstPage"
          @prevPage="prevPage"
          @nextPage="nextPage"
          @lastPage="lastPage"
          @pageSizeSelected="pageSizeSelected"
        >
          <template #perPageOptions>
            <option value="5">
              5
            </option>
            <option value="10">
              10
            </option>
            <option value="20">
              20
            </option>
            <option value="50">
              50
            </option>
            <option value="100">
              100
            </option>
          </template>
        </Pagination>

        <span 
          v-if="onlyShowMyOpenOffers"
          class="mx-auto"
        >
          <button
            v-if="!batchSelect"
            class="nes-btn is-primary text-xs"
            @click="batchSelect = true"
          >
            Start Batch Cancel
          </button>
          <button
            v-if="batchSelect && !upgrading"
            class="nes-btn is-primary text-xs"
            @click="cancelTrade(selectedTrades)"
          >
            Cancel {{ selectedTrades.length }}
          </button>
          <button
            v-if="batchSelect && !upgrading"
            class="nes-btn is-normal text-xs"
            @click="batchSelect = false; selectedTrades.length = 0"
          >
            Stop
          </button>
        </span>

        <div
          v-for="(trade, tradekey) in trades"
          v-show="!onlyTradesICanTake || (onlyTradesICanTake && trade.canTrade)"
          :key="tradekey"
          class="flex flex-col nes-container is-rounded text-center"
        >
          <AddrIcon
            :address="trade.owner"
            :alias="trade.alias"
          /> will give
          <div class="flex flex-col items-left text-left">
            <div
              v-if="trade.price_offered > 0"
              class="text-sm font-medium text-center text-gray-900 p-1"
            >
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                {{ state.convertFromMutez(trade.price_offered) }} tez
              </span>
            </div>
            <div
              v-for="(tok, okey) in trade.offered"
              :key="okey"
              class="text-sm font-medium text-gray-900 p-1"
            >
              <span
                v-if="okey > 0"
                class="mb-5 text-gray-500"
              >
                and
              </span>
              <div class="flex items-center">
                <div class="flex-shrink-0 w-10">
                  <img
                    class="w-10 squared-full"
                    :src="tok.metadata.displayUri"
                    alt=""
                  >
                </div>
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    {{ tok.metadata.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ tok.metadata.rarity }} x{{ tok.amount }}
                  </div>
                  <div class="text-sm text-gray-500">
                    Token ID: {{ tok.tokenId }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          for
          <div class="flex flex-col items-left text-left">
            <div
              v-for="(tok, okey) in trade.accepted"
              :key="okey"
              class="text-sm font-medium text-gray-900 p-1"
            >
              <span
                v-if="okey > 0"
                class="mb-5 text-gray-500"
              >
                and
              </span>
              <div class="flex items-center">
                <div class="flex-shrink-0 w-10">
                  <img
                    class="w-10 squared-full"
                    :src="tok.metadata.displayUri"
                    alt=""
                  >
                </div>
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    {{ tok.metadata.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ tok.metadata.rarity }} x{{ tok.amount }}
                  </div>
                  <div class="text-sm text-gray-500">
                    Token ID: {{ tok.tokenId }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="trade.price_accepted > 0"
              class="text-sm font-medium text-gray-900 p-1"
            >
              <span v-if="trade.price_accepted && trade.accepted.length > 0">or</span>
              <span
                v-if="trade.price_accepted"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                {{ state.convertFromMutez(trade.price_accepted) }} tez
              </span>
            </div>
          </div>
          <hr class="p-1">
          <span
            v-if="viewAllTrades"
            class="grid grid-cols-2 gap-1"
          >
            <button
              v-if="trade.accepted.length > 0"
              :disabled="!trade.canTrade"
              :class="{'is-disabled': !trade.canTrade, 'is-primary': trade.canTrade}"
              class="nes-btn"
              @click="takeTrade(trade)"
            >
              <span>
                <span
                  v-if="trade.canTrade"
                >
                  Trade
                </span>
                <span
                  v-else
                  class="line-through text-gray-500 hover:text-gray-500"
                  title="You don't have the required potus to trade."
                >
                  Trade
                </span>
              </span>
            </button>
            <button
              v-if="trade.price_accepted > 0"
              class="nes-btn is-primary"
              @click="takeTrade(trade, true)"
            >
              <span>
                Buy
              </span>
            </button>

            <button
              class="nes-btn is-primary"
              :class="{'col-span-2': trade.accepted.length > 0 && trade.price_accepted > 0}"
              @click="doCopy(trade)"
            >
              <span>
                Copy Link
              </span>
            </button>

            <button
              v-if="trade.owner == state.userAddress && !batchSelect"
              class="nes-btn text-blue-link hover:text-blue-hov col-span-2"
              @click="cancelTrade(trade)"
            >
              Cancel
            </button>

            <div
              v-if="batchSelect"
              class="col-span-2"
            >
              <div
                class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in"
              >
                <input
                  type="checkbox"
                  class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                  @input="(e) => selectForBatch(e, trade)"
                >
                <label
                  class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                /> 
              </div>
              <label
                class="text-xs text-gray-700 inline"
              >Cancel?</label>
            </div>
          </span>
        </div>
        <Pagination 
          :offset="offset"
          :limit="limit"
          :pages="pages"
          @firstPage="firstPage"
          @prevPage="prevPage"
          @nextPage="nextPage"
          @lastPage="lastPage"
          @pageSizeSelected="pageSizeSelected"
        >
          <template #perPageOptions>
            <option value="5">
              5
            </option>
            <option value="10">
              10
            </option>
            <option value="20">
              20
            </option>
            <option value="50">
              50
            </option>
            <option value="100">
              100
            </option>
          </template>
        </Pagination>
      </div>
    </div>
    
    <div
      v-if="viewHistory"
      class="flex flex-col py-6 bg-white"
    >
      <div class="px-4 sm:px-6">
        <h2
          class="text-lg text-center font-medium text-gray-900"
        >
          Trade History
        </h2>
      </div>
      <div class="mt-6 relative flex-1 px-2 sm:px-2 text-sm pb-12">
        <div class="px-2 sm:px-2 divide-y">
          <div
            v-for="(item, key) in history.items"
            :key="key"
            class="text-left"
          >
            <div class="flex flex-col items-left p-2">
              <span class="text-xs text-gray-500">{{ item.timestamp.fromNow() }}</span>
              <div
                class=""
              >
                <span
                  v-if="item.parameter.entrypoint == 'take_trade'"
                >
                  <AddrIcon
                    :address="item.sender.address"
                    :alias="item.sender.alias"
                  /> took <AddrIcon
                    :address="item.trade.owner"
                    :alias="item.trade.alias"
                  />'s trade:<br>
                  {{ item.action }}
                </span>
                <span
                  v-if="item.parameter.entrypoint == 'bid'"
                >
                  <AddrIcon
                    :address="item.sender.address"
                    :alias="item.sender.alias"
                  /> became high bidder on <AddrIcon
                    :address="item.trade.owner"
                    :alias="item.trade.alias"
                  />'s auction:<br>
                  {{ item.action }}
                </span>
                <a
                  :href="`https://tzkt.io/${item.hash}`"
                  target="_blank"
                ><fa 
                  icon="external-link-alt" 
                  class="ml-1 align-baseline"
                /></a>
              </div>
            </div>
          </div>
          <button
            class="text-sm uppercase w-full py-4 px-6 nes-btn is-primary"
            :disabled="loading"
            @click="loadMoreHistory"
          >
            <span v-if="loading">
              <fa 
                icon="cog" 
                class="animate-spin h-5 w-5 mr-3"
              />
              Loading more...
            </span>
            <span v-if="!loading">Load more</span>
          </button>
        </div>
      </div>
    </div>
    
    <Auctions v-if="viewAuctions" />
    
    <SpeechBubble
      :img="state.images.pierce"
      :show="trading"
      :potus="'Pierce'"
      :is-left="false"
    >
      Enjoy this random POTUS gif while u wait. You can also go do other stuff without affecting the transaction. <br>
      <img
        :src="nextGif"
        width="300"
        class="m-auto"
      >
    </SpeechBubble>
    
    <SpeechBubble
      :img="state.images.eagleRight"
      :show="true"
      class="pb-16"
    >
      Come on over to the official Pixel discord to chat with other constituents, post your trades and get support.<br>
      <a
        href="https://discord.gg/CbdbvwtwkM"
        target="_blank"
      >
        Click me
        <img
          class="inline align-baseline"
          width="25"
          src="../assets/discord.png"
        >
      </a>
    </SpeechBubble>
  </div>

  <offer-trade
    :all-tokens="state.allTokens"
    :my-tokens="state.myTokens"
    :is-open="isOpen"
    :toggle-open="() => {isOpen = !isOpen}"
    :trading-fee="tradingFee"
    :refresh="refresh"
  />
</template>

<script>
import { OpKind } from '@taquito/taquito'
import Slider from '@vueform/slider'
import axios from 'axios'
import { debounce,find,findIndex } from 'lodash'
import moment from 'moment'
import VueMultiselect from 'vue-multiselect'
import { copyText } from 'vue3-clipboard'

import AddrIcon from '../components/AddrIcon'
import Pagination from '../components/Pagination'
import SpeechBubble from '../components/SpeechBubble'
import Auctions from './Auctions.vue'
import OfferTrade from './OfferTrade.vue'

export default {
  name: 'Market',
  components: {
    VueMultiselect,
    OfferTrade,
    SpeechBubble,
    AddrIcon,
    Auctions,
    Slider,
    Pagination,
  },
  data () {
    return {
      state: this.$root.$data.state,
      trades: [],
      totalTrades: 0,
      myTrades: [],
      totalMyTrades: 0,
      myPages: 1,
      auctions: [],
      totalAuctions: 0,
      tradingFee: this.$root.$data.state.tradingFee,
      confirmation: false,
      isOpen: false,
      loading: false,
      trading: false,
      viewAllTrades: true,
      viewAuctions: false,
      viewHistory: false,
      showHelp: true,
      filter: '',
      showFilters: true,
      onlyTradesICanTake: false,
      onlyShowMyOpenOffers: false,
      applyToOffering: true,
      applyToAccepting: true,
      showEndedAuctions: false,
      sortDir: 'desc',
      sortDirBool: true,
      orderBy: 'timestamp',
      tradeTypes: [],
      tradeTypesOptions: ['Buy Offers', 'Sell Offers', 'Trade Offers'],
      sortedBy: 'Newest First',
      sortedByOptions: ['Newest First', 'Oldest First', 'Asking Price: Low', 'Asking Price: High', 'Offer Price: Low', 'Offer Price: High'],
      rarities: [],
      raritiesOptions: ['common','uncommon','rare','epic','legendary','unique'],
      askingPriceRange: [0, 20],
      askingPriceRangeMax: 20,
      hidePaid: false,
      limit: 10,
      offset: 0, 
      getLoop: true,
      nextGif: null,
      tradeCompleteText: 'completed',
      batchSelect: false,
      selectedTrades: [],
      history: {
        offset: 0,
        limit: 25,
        items: [],
      },
      autosave: {
        filter: '',
        onlyShowMyOpenOffers: false,
        onlyTradesICanTake: false,
        tradeTypes: [],
        sortedBy: '',
        rarities: [],
        askingPriceRange: [0, 20],
      },
    }
  },
  computed: {
    pages () {
      if (this.viewAllTrades) {
        if (this.trades.length === 0) {
          return 1
        }
        let tot = parseInt((this.totalTrades / this.limit).toFixed(0))
        if (tot < (this.totalTrades / this.limit)) { tot++ }
        return tot
      } else {
        if (this.auctions.length === 0) {
          return 1
        }
        let tot = parseInt((this.totalAuctions / (this.limit-1)).toFixed(0))
        if (tot < (this.totalAuctions / (this.limit-1))) { tot++ }
        return tot
      }
    },
  },
  watch: {
    filter() {
      this.saveFilters()
    },
    onlyShowMyOpenOffers() {
      this.saveFilters()
    },
    onlyTradesICanTake() {
      this.saveFilters()
    },
    tradeTypes() {
      this.saveFilters()
    },
    sortedBy() {
      this.saveFilters()
    },
    rarities() {
      this.saveFilters()
    },
    askingPriceRange() {
      this.saveFilters()
    },
  },
  async mounted() {
    this.state.log('mounted')

    const filters = localStorage.getItem('tradeFilters')
    const autosave = JSON.parse(filters)
    if (autosave) {
      this.filter = autosave.filter
      this.onlyShowMyOpenOffers = autosave.onlyShowMyOpenOffers
      this.onlyTradesICanTake = autosave.onlyTradesICanTake
      this.tradeTypes = autosave.tradeTypes
      this.sortedBy = autosave.sortedBy
      this.rarities = autosave.rarities
      this.askingPriceRange = autosave.askingPriceRange
      if(this.askingPriceRange[1] > 20) {
        this.askingPriceRange[1] = 20
      }
      this.sortCondition()
    }

    if (this.state.userAddress === null) {
      this.$router.push("/")
    }
    const sh = localStorage.getItem('showTradeHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    this.getLoop = true
    await this.getTrades()
  },
  async unmounted() {
    this.state.log('unmounted')
    this.getLoop = false
  },
  methods: {
    clearFilters(fetchTrades = true) {
      this.offset = 0
      this.filter = ''
      this.onlyShowMyOpenOffers = false
      this.onlyTradesICanTake = false
      this.tradeTypes.length = 0
      this.sortedBy = 'Newest First'
      this.rarities.length = 0
      this.askingPriceRange = [0, 20]
      this.saveFilters()
      if (fetchTrades) {
        this.getTrades()
      }
    },
    saveFilters() {
      this.autosave.filter = this.filter
      this.autosave.onlyShowMyOpenOffers = this.onlyShowMyOpenOffers
      this.autosave.onlyTradesICanTake = this.onlyTradesICanTake
      this.autosave.tradeTypes = this.tradeTypes
      this.autosave.sortedBy = this.sortedBy
      this.autosave.rarities = this.rarities
      this.autosave.askingPriceRange = this.askingPriceRange
      localStorage.setItem('tradeFilters', JSON.stringify(this.autosave))
    },
    priceFormat: function (value) {
      if(value == this.askingPriceRangeMax) return 'Max'
      return `${value.toFixed(1)}ꜩ`
    },
    changeToView (view) {
      this.viewAllTrades = false
      this.viewAuctions = false
      this.viewHistory = false
      this[view] = true
    },
    toggleSelectedTrade (e, trade) {
      trade.isSelected = e.target.checked
      if(e.target.checked) {
        this.selectedTrades.push(trade)
      } else {
        const idx = findIndex(this.selectedTrades, (t) => {
          return t.tradeId === trade.tradeId
        })
        if (idx !== -1) {
          this.selectedTrades.splice(idx, 1)
        }
      }
    }, 
    async refresh() {
      this.getTrades()
    },
    doCopy(trade) {
      copyText(`https://www.pixelpotus.com/trades/${trade.owner}/${trade.tradeId}`, undefined, (error, event) => {
        if (error) {
          alert('Can not copy trade')
          this.state.log(error)
        } else {
          this.state.log(event)
        }
      })
    },
    async tradeTypesSelected () {
      await new Promise((resolve) => setTimeout(resolve, 250))
      this.offset = 0
      this.getTrades()
    },
    async pageSizeSelected (value) {
      this.offset = 0
      this.limit = parseInt(value)
      this.getTrades()
    },
    async sortedBySelected () {
      await new Promise((resolve) => setTimeout(resolve, 250))
      this.sortCondition()
      this.offset = 0
      this.getTrades()
    },
    sortCondition() {
      switch(this.sortedBy) {
        case 'Oldest First':
          this.sortDir = 'asc'
          this.orderBy = 'timestamp'
          break
        case 'Asking Price: Low':
          this.sortDir = 'asc'
          this.orderBy = 'price_accepted'
          break
        case 'Asking Price: High':
          this.sortDir = 'desc'
          this.orderBy = 'price_accepted'
          break
        case 'Offer Price: Low':
          this.sortDir = 'asc'
          this.orderBy = 'price_offered'
          break
        case 'Offer Price: High':
          this.sortDir = 'desc'
          this.orderBy = 'price_offered'
          break
        default:
          this.sortedBy = 'Newest First'
          this.sortDir = 'desc'
          this.orderBy = 'timestamp'
          break
      }
    },
    async updateCheckbox(event = null) {
      await new Promise((resolve) => setTimeout(resolve, 150))
      
      if(this.onlyShowMyOpenOffers) {
        this.clearFilters(false)
        this.onlyShowMyOpenOffers = true
      }
      if(this.onlyTradesICanTake) {
        this.limit = 100
      }
      
      if(event && event.target.id === 'toggleApplyToOffering') {
        if(!this.applyToOffering) {
          if(!this.applyToAccepting) {
            this.applyToOffering = true
          }
        }
      }
      if(event && event.target.id === 'toggleApplyToAccepting') {
        if(!this.applyToAccepting) {
          if(!this.applyToOffering) {
            this.applyToAccepting = true
          }
        }
      }

      this.offset = 0
      this.getTrades()
    },
    updateFilters() {
      this.debouncedUpdate()
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.getTrades()
    }, 600),
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showTradeHelp', JSON.stringify(false))
    },
    canTrade(trade) {
      if(trade.accepted.length === 0) {
        return false
      }
      for (const tok of trade.accepted) {
        if (typeof find(this.state.myTokens, (t) => {return (t.tokenId === tok.tokenId && t.balance >= tok.amount)}) === 'undefined') {
          return false
        }
      }
      return true
    },
    getMd(tokenId) {
      return find(this.state.allTokens, (t) => {return t.tokenId === tokenId})
    },
    selectForBatch(e, trade) {
      if (e.target.checked) {
        this.selectedTrades.push(trade)
      } else {
        const idx = findIndex(this.selectedTrades, (t) => t.tradeId == trade.tradeId)
        this.selectedTrades.splice(idx, 1)
      }
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.totalTrades) {this.offset -= this.limit}
      else { this.getTrades() }
    },
    prevPage() {
      this.offset -= this.limit
      if (this.offset < 0) {this.offset = 0}
      else { this.getTrades() }
    },
    firstPage() {
      this.offset = 0
      this.getTrades()
    },
    lastPage() {
      this.offset = (this.pages - 1) * this.limit
      this.getTrades()
    },
    async getTrades () {
      this.loading = true
      if (!this.state.myTokensLoaded) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        await this.getTrades()
      } else {
        this.state.log('getTrades')
        this.loading = true

        const params = {
          limit: this.limit,
          offset: this.offset,
          filter: this.filter,
          sortDir: this.sortDir,
          orderBy: this.orderBy,
          rarities: this.rarities.join(','),
          types: this.tradeTypes.map(t=>t.toLowerCase().split(' ')[0]).join(','),
          askingPriceRange: this.askingPriceRange.join(','),
          filterOffer: this.applyToOffering,
          filterAccept: this.applyToAccepting,
        }
        if (this.onlyShowMyOpenOffers) {
          params.owner = this.state.userAddress
        }
        const resp = await axios({
          url:'/api/trades', 
          params,
        })
        this.trades = resp.data.trades.map((trade) => {
          trade.canTrade = this.canTrade(trade)
          const alias = find(this.state.aliases, (a) => {return a.address === trade.owner})
          if(alias) {
            trade.alias = alias.alias
          }
          return trade
        })
        this.totalTrades = resp.data.total

        this.loading = false
      }
    },
    async takeTrade (trade, purchase) {
      this.state.log(trade, purchase)
      this.loading = true
      this.trading = true
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.confirmation = false
      try{
        let operators = []
        const opts = {}
        if (purchase) {
          opts.amount = trade.price_accepted
          opts.mutez = true
        } else {
          operators = trade.accepted.map(t => {
            return { 
              add_operator: { 
                owner: this.state.userAddress,
                operator: process.env.VUE_APP_PP_MARKET,
                token_id: t.tokenId,
              },
            }
          })
        }

        let transactions = []
        if (operators.length > 0) {
          transactions.push({
                kind: OpKind.TRANSACTION,
                ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
              })
        }
        transactions.push({
              kind: OpKind.TRANSACTION,
              ... this.state.ppMarket.methods.take_trade(trade.owner, trade.tradeId).toTransferParams(),
              ...opts,
            })

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)
        
        const tradeidx = findIndex(this.trades, t => t.owner === trade.owner && t.tradeId === trade.tradeId)
        if (tradeidx > -1) {
          this.trades.splice(tradeidx, 1)
        }

        this.loading = false
        this.trading = false
        this.tradeCompleteText = 'completed'
        this.confirmation = true
        setTimeout(()=>{this.confirmation = false}, 30000)

        for (const nt of trade.offered) {
          const newToken = find(this.state.allTokens, (tok) => tok.tokenId === nt.tokenId)
          const idx = findIndex(this.state.myTokens, mt => mt.tokenId === newToken.tokenId)
          if (idx > -1) {
            this.state.myTokens[idx].balance += nt.amount
          } else {
            this.state.myTokens.push({ ...newToken, balance: nt.amount, upgrading: false })
          }
          this.state.sortTokens(this.state.sortBy, true)
        }

        for (const nt of trade.accepted) {
          const idx = findIndex(this.state.myTokens, mt => mt.tokenId === nt.tokenId)
          if (idx > -1) {
            this.state.myTokens[idx].balance -= nt.amount
          }
          this.state.sortTokens(this.state.sortBy, true)
        }

        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)

      } catch(e) {
        this.loading = false
        this.trading = false
        console.error('Unable to take trade', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async cancelTrade (trades) {
      this.loading = true
      this.trading = true
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.confirmation = false
      try{
        if (!Array.isArray(trades)) {
          trades = [trades]
        }

        const transactions = []
        trades.forEach((trade) => {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppMarket.methods.cancel_trade(trade.owner, trade.tradeId).toTransferParams(),
          })
        })
        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)

        await Promise.all(trades.map(async (trade) => {
          const tradeidx = findIndex(this.trades, t => t.owner === trade.owner && t.tradeId === trade.tradeId)
          if (tradeidx > -1) {
            this.trades.splice(tradeidx, 1)
          }

          for (const nt of trade.offered) {
            const newToken = find(this.state.allTokens, (tok) => tok.tokenId === nt.tokenId)
            const idx = findIndex(this.state.myTokens, mt => mt.tokenId === newToken.tokenId)
            if (idx > -1) {
              this.state.myTokens[idx].balance += nt.amount
            } else {
              this.state.myTokens.push({ ...newToken, balance: nt.amount, upgrading: false })
            }
            this.state.sortTokens(this.state.sortBy, true)
          }
        }))

        this.selectedTrades.length = 0
        this.batchSelect = false
        this.loading = false
        this.trading = false
        this.tradeCompleteText = 'canceled'
        this.confirmation = true
        setTimeout(()=>{this.confirmation = false}, 30000)
        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)

      } catch(e) {
        this.loading = false
        this.trading = false
        console.error('Unable to cancel trade', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async loadMoreHistory () {
      if(this.history.items.length > 0) {
        this.history.offset += this.history.limit
      }
      this.getHistory()
    },
    async getHistory () {
      try{
        this.loading = true

        const historyResp = await axios({
          url:'/api/getTradeHistory', 
          params: {
            limit: this.history.limit,
            offset: this.history.offset,
          },
        })
        this.history.items = [].concat(this.history.items, historyResp.data.transactions.map(t => {
          t.timestamp = moment(t.timestamp)
          const alias = find(this.state.aliases, (a) => {return a.address === t.sender.address})
          if(alias) {
            t.sender.alias = alias.alias
          }
          const alias2 = find(this.state.aliases, (a) => {return a.address === t.trade.owner})
          if(alias2) {
            t.trade.alias = alias2.alias
          }
          return t
        }))

        this.loading = false

      } catch(e) {
        this.loading = false
        console.error('Unable to get trade history', e)
      }
    },
  },
}
</script>