<template>
  <header 
    id="nav" 
    class="sticky top-0 z-40 bg-white"
  >
    <Crest
      :state="state"
      :connect-wallet="connectWallet"
    />
    <div
      :class="state.userAddress ? 'lg:grid-cols-8' : 'lg:grid-cols-7'"
      class="hidden md:grid md:grid-cols-3 md:grid-rows-2 lg:grid-rows-1 items-center justify-between py-4 gap-4"
    >
      <router-link 
        v-for="(link, i) in navLinks"
        :key="i"
        class="text-base font-medium text-gray-500 hover:text-gray-900"
        :to="link.path"
      >
        {{ link.label }}
      </router-link>
    </div>
  </header>
</template>

<script>
import Crest from './components/Crest.vue'
import links from './components/nav-links'
export default {
  name: 'Header',
  components: {
    Crest,
  },
  props: {
    connectWallet: { 
      type: Function,
      default: () => { return {}},
    },
  },
  data () {
    return {
      state: this.$root.$data.state,
    }
  },
  computed: {
    navLinks () {
      return links(this.state)
    },
  },
}
</script>

<style scoped>
  /* .router-link-active {
    text-decoration: underline;
  } */
</style>