<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-2 items-center justify-between text-left mt-12 overflow-hidden">
    <h1 class="text-2xl text-center">
      <span v-if="!trading">View Trade</span>
      <span v-else><fa 
        icon="cog" 
        class="animate-spin h-5 w-5 mr-3"
      />Executing trade! Please wait up to 1 min for block confirmation.</span>
    </h1>
    <div
      v-if="trade === false"
      class="text-center"
    >
      Unable to load trade. Please try again in a few moments.
    </div>
    <div
      v-if="trade && !trading"
      class="text-center"
    >
      <AddrIcon
        :address="trade.owner"
        :alias="trade.alias"
      /> is offering a trade!
    </div>
    <div
      v-if="trade && !trading"
      class="text-left nes-container is-rounded p-5 grid grid-cols-1 sm:grid-cols-3"
    >
      <div class="flex flex-col items-left">
        Will give:
        <div
          v-if="trade.price_offered > 0"
          class="text-sm font-medium text-gray-900 p-1"
        >
          <span
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            {{ state.convertFromMutez(trade.price_offered) }} tez
          </span>
        </div>
        <div
          v-for="(tok, okey) in trade.offered"
          :key="okey"
          class="text-sm font-medium text-gray-900 p-1"
        >
          <span
            v-if="okey > 0"
            class="mb-5 text-gray-500 text-left"
          >
            and
          </span>
          <div class="flex items-center">
            <div class="flex-shrink-0 w-10">
              <img
                class="w-10 squared-full"
                :src="tok.metadata.displayUri"
                alt=""
              >
            </div>
            <div class="ml-4">
              <div class="text-sm font-medium text-gray-900">
                {{ tok.metadata.shortName }}
              </div>
              <div class="text-sm text-gray-500">
                {{ tok.metadata.rarity }} x{{ tok.amount }}
              </div>
              <div
                v-if="tok.tokenId > 6000 && tok.payout == null"
                class="text-xs text-gray-600"
              >
                (Includes 25% treasury claim!)
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-left">
        For:
        <div
          v-for="(tok, okey) in trade.accepted"
          :key="okey"
          class="text-sm font-medium text-gray-900 p-1"
        >
          <span
            v-if="okey > 0"
            class="mb-5 text-gray-500 text-left"
          >
            and
          </span>
          <div class="flex items-center">
            <div class="flex-shrink-0 w-10">
              <img
                class="w-10 squared-full"
                :src="tok.metadata.displayUri"
                alt=""
              >
            </div>
            <div class="ml-4">
              <div class="text-sm font-medium text-gray-900">
                {{ tok.metadata.shortName }}
              </div>
              <div class="text-sm text-gray-500">
                {{ tok.metadata.rarity }} x{{ tok.amount }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="trade.price_accepted > 0"
          class="text-sm font-medium text-gray-900 p-1"
        >
          <span v-if="trade. price_accepted && trade.accepted.length > 0">or</span>
          <span
            v-if="trade. price_accepted"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            {{ state.convertFromMutez(trade. price_accepted) }} tez
          </span>
        </div>
      </div>

      <div
        class="col-span-1 grid grid-cols-1 gap-6 p-4 text-gray-600"
      >
        <div v-if="trade.accepted.length > 0">
          <button
            class="text-sm uppercase p-4 nes-btn w-full"
            :disabled="!trade.canTrade || trading"
            :class="{'is-disabled': !trade.canTrade || trading, 'is-primary': trade.canTrade && !trading}"
            :title="!trade.canTrade ? `You don't have the required tokens` : `Trade your tokens for theirs!`"
            @click="takeTrade(trade)"
          >
            Trade Your Tokens
          </button>
          <span
            v-if="!state.myTokensLoaded"
            class="text-xs text-center"
          >(Loading your tokens...)</span>
          <span
            v-else-if="!trade.canTrade"
            class="text-xs text-center"
          >(You don't have the required tokens)</span>
        </div>
        <div v-if="trade.price_accepted > 0">
          <button
            :disabled="trade.price_accepted === 0"
            :class="{'is-disabled': trade.price_accepted === 0, 'is-primary': trade.price_accepted > 0}"
            class="text-sm uppercase p-4 nes-btn w-full"
            :title="trade.price_accepted === 0 ? 'Trade owner is not accepting payment, only tokens.' : `Purchase tokens for ${state.convertFromMutez(trade.price_accepted)}tez`"
            @click="takeTrade(trade, true)"
          >
            Buy Tokens for {{ state.convertFromMutez(trade.price_accepted) }}ꜩ
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      Loading...
    </div>
    <SpeechBubble
      :img="state.images.pierce"
      :show="trading"
      :potus="'Pres. Pierce'"
      :is-left="false"
    >
      Enjoy this random POTUS gif while u wait. You can also go do other stuff without affecting the transaction. <br>
      <img
        :src="nextGif"
        width="300"
        class="m-auto"
      >
    </SpeechBubble>
  </div>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find, findIndex } from 'lodash'

import AddrIcon from '../components/AddrIcon'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'ViewTrade',
  components: {
    AddrIcon,
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      trade: null,
      tradeId: null,
      tradingFee: 25/1000,
      confirmation: false,
      loading: false,
      trading: false,
      nextGif: null,
    }
  },
  watch: {
    $route() {
      
    },
  },
  async mounted() {
    this.state.log('mounted viewtrade')
    this.getId()
  },
  async unmounted() {
    this.state.log('unmounted viewtrade')
  },
  methods: {
    async canTrade() {
      if (!this.state.myTokensLoaded) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.canTrade()
      }
      if(this.trade.accepted.length === 0) {
        this.trade.canTrade = false
        return
      }
      for (const tok of this.trade.accepted) {
        if (typeof find(this.state.myTokens, (t) => {return (t.tokenId === tok.tokenId && t.balance >= tok.amount)}) === 'undefined') {
          this.trade.canTrade = false
          return
        }
      }
      this.trade.canTrade = true
    },
    async getId () {
      try{
        if (this.$route.params.owner && this.$route.params.tradeId) {
          this.loading = true
          await this.getTrades()
        } else {
          this.potusId = null
          this.trade = null
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    async getPayoutStatus (tokenId) {
      try {
        const resp = await axios({
          url:'/api/treasury', 
        })
        const payout = find(resp.data.treasury.payouts, (p) => {
          return tokenId === p.tokenId
        })
        if(typeof payout !== 'undefined') {
          return payout
        } 
        return null
      } catch (error) {
        return null
      }
    },
    async getTrades () {
      if (this.state.allTokens.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.getTrades()
      }

      this.state.log('getTrades')

      this.tradeId = this.$route.params.tradeId
      const owner = this.$route.params.owner
      const resp = await axios({
          url:'/api/trades',
          params: {
            owner,
            tradeId: this.tradeId,
          },
        })

      this.trade = resp.data.trades.length ? resp.data.trades[0] : false

      this.trade.offered = await Promise.all(this.trade.offered.map(async o => {
        if (o.tokenId > 6000) {
          o.payout = await this.getPayoutStatus(o.tokenId)
        }
        return o
      }))

      this.canTrade()

      const alias = find(this.state.aliases, (a) => {return a.address === this.trade.owner})
      if(alias) {
        this.trade.alias = alias.alias
      }
      
      this.state.log(this.$route.params, this.trade)

      // this.tradingFee = s.trading_fee.toNumber() / 1000
      this.loading = false
    },
    async takeTrade (trade, purchase) {
      this.state.log(trade, purchase)
      this.loading = true
      this.trading = true
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.confirmation = false
      try{
        let operators = []
        const opts = {}
        if (purchase) {
          opts.amount = trade.price_accepted
          opts.mutez = true
        } else {
          operators = trade.accepted.map(t => {
            return { 
              add_operator: { 
                owner: this.state.userAddress,
                operator: process.env.VUE_APP_PP_MARKET,
                token_id: t.tokenId,
              },
            }
          })
        }

        let transactions = []
        if (operators.length > 0) {
          transactions.push({
                kind: OpKind.TRANSACTION,
                ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
              })
        }
        transactions.push({
              kind: OpKind.TRANSACTION,
              ... this.state.ppMarket.methods.take_trade(trade.owner, trade.tradeId).toTransferParams(),
              ...opts,
            })

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)

        this.loading = false
        this.trading = false

        for (const nt of trade.offered) {
          const newToken = find(this.state.allTokens, (tok) => tok.tokenId === nt.tokenId)
          const idx = findIndex(this.state.myTokens, mt => mt.tokenId === newToken.tokenId)
          if (idx > -1) {
            this.state.myTokens[idx].balance += nt.amount
          } else {
            this.state.myTokens.push({ ...newToken, balance: nt.amount, upgrading: false })
          }
          this.state.sortTokens(this.state.sortBy, true)
        }

        for (const nt of trade.accepted) {
          const idx = findIndex(this.state.myTokens, mt => mt.tokenId === nt.tokenId)
          if (idx > -1) {
            this.state.myTokens[idx].balance -= nt.amount
          }
          this.state.sortTokens(this.state.sortBy, true)
        }

        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)
        this.$router.push(`/me`)
      } catch(e) {
        this.loading = false
        this.trading = false
        console.error('Unable to take trade', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
  },
}
</script>
