<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left mt-12 pb-32">
    <div
      class="nes-container is-rounded is-dark text-xs md:text-base"
    >
      <div 
        class="flex flex-col md:flex-row items-center justify-center"
      >
        <img
          src="https://cloudflare-ipfs.com/ipfs/Qma4wzNogtUkuhMgzyKBqzaYBqabLVZrHcLbLmJE38B5XH"
          width="100"
        >
        <h1 class="md:pl-10 text-3xl text-center">
          Pixel Token (PXL) Private Sale
        </h1>
      </div>
      <br>
      <p class="p-5">
        <ul class="list-disc leading-loose">
          <li>By participating in the crowdsale, you acknowledge and accept all terms below</li>
          <li>100,000,000: Total supply of PXL</li>
          <li>15,000,000: 15% allocated to this private sale</li>
          <li>Minimum contribution is 500 XTZ. No maximum</li>
          <li>Token exchange rate: {{ salePrice }} XTZ per PXL. <span class="text-xs">This is targeted to be at 50% discount from the IFO and DEX listing price, although XTZ/USD prices may cause fluctuations.</span></li>
          <li>First come, first served</li>
          <li>
            There is a three-month lock on the tokens followed by a five-month slow release that will be powered by <a
              href="https://crunchy.network/"
              target="_blank"
            >Crunchy</a>
          </li>
          <li>This page is IP-protected to prevent participation in certain jurisdictions</li>
          <li>Sale Starts: {{ saleStartTime }}</li>
          <li>Sale Ends: {{ saleEndTime }}</li>
        </ul>
      </p>
      <div
        v-if="!saleStarted"
        class="flex flex-col items-center justify-center text-center text-4xl"
      >
        Sale begins in <span class="golden-alias">{{ countdown }}</span>
      </div>
      <div 
        v-else
        class="flex flex-col items-center justify-center"
      >
        <div
          v-if="!canParticipate"
          class="flex flex-row items-center justify-center border-2 border-white border-dashed p-5"
        >
          <div class="text-lg">
            Sorry friend, it looks like you are connecting from an unsupported jurisdiction and will not be able to continue.
          </div>
        </div>
        <div
          v-if="!agreed && canParticipate"
          class="flex flex-row items-center justify-center border-2 border-white border-dashed p-2"
        >
          <span class="p-5">
            <input
              type="checkbox"
              @change="()=>{saveDisclaimer();}"
            >
          </span>
          <div class="text-lg">
            I confirm that I have read and agree to the terms above and I am not a United States citizen.
          </div>
        </div>
        <div
          v-if="agreed && canParticipate"
          class="flex flex-col items-center justify-center border-2 border-white border-dashed p-5 w-full xl:w-2/3"
        >
          <div v-if="!state.userAddress">
            <button
              class="self-center text-sm uppercase py-4 px-6 text-white bg-blue-dark hover:bg-blue-light border-2 border-gray-700 nes-btn is-primary h-20"
              @click="connectWallet"
            >
              Connect Wallet
            </button>
          </div>
          <div
            v-else
            class="flex flex-col items-center text-center justify-center"
          >
            <span class="text-xl">Send XTZ to purchase PXL</span>
            <span>1 PXL = {{ salePrice }} XTZ</span>
            <span>Amount sold: {{ soldAmount }} / {{ saleSupply }}</span>
            <div
              v-if="!soldOut"
              class="flex flex-col sm:flex-row items-center justify-center p-5"
            >
              <input
                v-model="buyAmount"
                type="number"
                min="500"
                step="100"
                class="w-full p-5 border-2 rounded-sm border-gray-700 text-black"
                style=""
              >
              <button
                class="w-full text-sm uppercase bg-green-300 hover:bg-green-400 border-2 border-gray-700 nes-btn is-primary h-16"
                @click="purchase"
              >
                <span v-if="saleTxPending">
                  <fa 
                    icon="cog" 
                    class="animate-spin h-5 w-5 mr-3"
                  />
                </span>
                Purchase
              </button>
            </div>
            <div
              v-if="soldOut"
              class="flex flex-col sm:flex-row items-center text-center justify-center p-5 text-xl"
            >
              Sold Out!
            </div>
            <span
              v-if="!confirmation && !soldOut"
              class="text-green-500"
            >You are sending {{ buyAmount }} XTZ and will receive <br>{{ (buyAmount/salePrice).toFixed(6) }} PXL in total.</span>
            <span
              v-if="confirmation"
              class="text-green-500"
            >Your purchase was successful!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

export default {
  name: 'Crowdsale',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
  data () {
    return {
      state: this.$root.$data.state,
      agreed: false,
      canParticipate: true,
      contract: null,
      countdown: null,
      interval: null,
      saleStartTime: '2021-10-24T19:00:00.000Z',
      saleEndTime: '2021-10-26T19:00:00.000Z',
      salePrice: .002,
      saleSupply: 0,
      soldAmount: 0,
      soldOut: false,
      buyAmount: 1000,
      initialLoading: true,
      saleTxPending: false,
      confirmation: false,
      updateSaleInt: null,
      tokenDecimals: 6,
      saleContract: process.env.VUE_APP_TEZOS_SALE_CONTRACT,
      saleStarted: false,
    }
  },
  async mounted() {
    await this.setup()

    const vm = this
    vm.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      vm.loadContractStorage()
      vm.updateSaleInt = setInterval(vm.loadContractStorage, 3 * 1000)

      var eventTime = moment(vm.saleStartTime)
      var currentTime = moment()

      var time = eventTime.unix() - currentTime.unix()
      var duration = moment.duration(time*1000, 'milliseconds')
      var interval = 1000

      if (time < 0) {
        this.saleStarted = true
      }

      this.interval = setInterval(() => {
        time = eventTime.unix() - currentTime.unix()
        if (time < 0) {
          this.saleStarted = true
        }

        duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
        this.countdown = `${duration.days()}d:${duration.hours()}h:${duration.minutes()}m:${duration.seconds()}s`
      }, interval)
    })
  },
  methods: {
    async setup () {
      const geoResp = await axios({
        url:'/api/geoip', 
      })
      let country = geoResp?.data?.geo?.country

      this.canParticipate = country !== 'US'
      console.log(country)
      this.contract = await this.state.tezos.wallet.at(this.saleContract)
    },
    async loadContractStorage () {
      const storage = await this.contract.storage()

      this.saleStartTime = new Date(storage.sale.startTime).toISOString()
      this.saleEndTime = new Date(storage.sale.endTime).toISOString()
      this.salePrice = storage.sale.price.toNumber() / (10 ** 6)

      this.saleSupply = storage.sale.saleSupply.toNumber() / (10 ** this.tokenDecimals)
      this.soldAmount = storage.sale.soldAmount.toNumber() / (10 ** this.tokenDecimals)
      if (this.soldAmount >= this.saleSupply) {
        this.soldOut = true
      }
      this.initialLoading = false
    },
    async loadDisclaimer () {
      const disclaimer = localStorage.getItem('notcitizen')
      if (disclaimer) {
        this.agreed = (JSON.parse(disclaimer))
      }
    },
    async saveDisclaimer () {
      this.agreed = true
      localStorage.setItem('notcitizen', true)
    },
    async purchase () {
      const amount = parseInt(this.buyAmount * 1000000)
      if (amount < 1) {
          return
      }

      try {
        this.confirmation = false
        this.saleTxPending = true
        const op = await this.contract.methods.default(null)
          .send({ amount: amount, mutez: true })
        await op.confirmation(1)
        this.confirmation = true
        setTimeout(()=>{this.confirmation = false}, 10000)
      } catch (e) {
        console.log(e)
      }
      this.saleTxPending = false
    },
  },
}
</script>