<template>
  <footer 
    class="flex flex-col md:flex-row items-center justify-center pt-5 pb-10"
  >
    <router-link 
      v-for="(link, i) in navLinks"
      :key="i"
      class="text-sm font-medium text-black hover:text-gray-800 p-2"
      :to="link.path"
    >
      {{ link.label }}
    </router-link>
  </footer>
</template>

<script>
import links from './components/footer-links'
export default {
  name: 'Footer',
  props: {
    connectWallet: { 
      type: Function,
      default: () => { return {}},
    },
  },
  data () {
    return {
      state: this.$root.$data.state,
    }
  },
  computed: {
    navLinks () {
      return links(this.state)
    },
  },
}
</script>

<style scoped>
  /* .router-link-active {
    text-decoration: underline;
    color: white;
  } */
  /* footer{
    width:100%;
  } */
</style>